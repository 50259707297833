import React, { useEffect, useRef } from "react";
import { FormControl } from "react-bootstrap";

const AutoGrowTextArea = ({ value, ...props }) => {
  const textareaRef = useRef(null);

  // Automatically adjust the height of the textarea as the content changes
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [value]);

  return <FormControl ref={textareaRef} value={value} {...props} />;
};

export default AutoGrowTextArea;

import moment from "moment";
import React, { createRef, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import {
  Check2,
  Check2All,
  ChevronDown,
  ChevronUp,
  Clock,
  SendFill,
  XLg,
} from "react-bootstrap-icons";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../helpers/constants";
import AutoGrowTextArea from "../AutoGrowTextArea";
import Loader from "../Loader";
import "./styles.scss";
import useLocalization from "../../../hooks/useLocalization";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const STATUS_ICONS = {
  read: (
    <Check2All className="whatsapp-chat-icon" style={{ color: "#53BDEB" }} />
  ),
  delivered: <Check2All className="whatsapp-chat-icon" />,
  sent: <Check2 className="whatsapp-chat-icon" />,
  undelivered: <Clock className="whatsapp-chat-icon" />,
};

const WhatsappChatScreen = ({
  containerRef,
  messageRef,
  minimized,
  notifications,
  onMinimizeClick,
  onMaximizeClick,
  userName,
  onCloseChat,
  messages,
  input,
  setInput,
  sendMessage,
  fetchingMessages,
  phoneNumbers,
  selectedPhoneNumber,
  onPhoneNumberChange,
  hasSeenMessage,
  markAsReadUnread,
  loading,
}) => {
  const { isRTL } = useLocalization();
  return (
    <div className={`chat-container ${minimized ? "minimized" : ""} `}>
      <div className="text-white d-flex justify-content-between align-items-center p-2">
        <div className="d-flex align-items-center gap-2">
          <h6 style={{ width: 120 }} className="truncate large mb-0">
            {userName}
          </h6>
          {minimized && notifications > 0 && (
            <div className="notification">{notifications}</div>
          )}
          {phoneNumbers.length > 1 && !minimized && (
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-light border-0"
                size="sm"
                className="px-1 py-0 mx-1"
                aria-label="more"
              >
                {selectedPhoneNumber}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {phoneNumbers.map((number, index) => (
                  <Dropdown.Item
                    key={number}
                    onClick={() => onPhoneNumberChange(number)}
                  >
                    <h6 className="smallFont mb-0">{number}</h6>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        <div className="d-flex align-items-center gap-2">
          {minimized ? (
            <ChevronUp className="hover" onClick={onMaximizeClick} />
          ) : (
            <ChevronDown className="hover" onClick={onMinimizeClick} />
          )}
          <XLg className="hover" onClick={onCloseChat} />
        </div>
      </div>
      {!minimized && (
        <>
          {" "}
          <div
            ref={containerRef}
            className={`chat-messages d-flex flex-column ${
              messages.length <= 1 ? "justify-content-end" : ""
            }`}
          >
            {fetchingMessages && messages.length === 0 ? (
              <div className="px-3">
                <Loader />
              </div>
            ) : messages.length > 0 ? (
              messages.map((message, index) => {
                const isSender = message.direction === "inbound";
                if (!messageRef.current[message.sid]) {
                  messageRef.current[message.sid] = createRef();
                }
                return (
                  <>
                    {fetchingMessages && (
                      <div className="px-3">
                        <Loader />
                      </div>
                    )}
                    <div
                      key={index}
                      className={`d-flex ${
                        isSender ? "" : "justify-content-end"
                      } px-3 `}
                    >
                      <div
                        ref={messageRef.current[message.sid]}
                        className={
                          isSender
                            ? isRTL
                              ? "rtl-sender-chat-bubble"
                              : "sender-chat-bubble"
                            : isRTL
                            ? "rtl-receiver-chat-bubble"
                            : "receiver-chat-bubble"
                        }
                      >
                        {message.body}
                        <div className="whatsapp-chat-footer">
                          <div className="d-flex align-items-center">
                            <h6 className="whatsapp-chat-time-text mb-0">
                              {moment(message.dateSent).format(
                                DEFAULT_DATE_TIME_FORMAT
                              )}
                            </h6>
                            {!isSender &&
                              (STATUS_ICONS[message.status] || (
                                <Clock className="whatsapp-chat-icon" />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="mt-5 bg-white large text-secondary mx-auto px-2 rounded">
                Nothing to show
              </div>
            )}
          </div>
          <div className="chat-input-container p-2">
            <AutoGrowTextArea
              size="sm"
              value={input}
              as="textarea"
              className="chat-input"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type a message"
              rows={1}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevent adding a new line
                  if (input.trim()) {
                    sendMessage(); // Send the message
                  }
                }
              }}
            />

            <Button
              size="sm"
              className="d-flex gap-1 align-items-center"
              variant="success text-white"
              onClick={sendMessage}
              disabled={!input || fetchingMessages}
            >
              Send
              <SendFill />
            </Button>
          </div>
          <div className="my-1 gap-x-2">
            {loading ? (
              <span className="text-white ms-2">
                {hasSeenMessage ? "Unresolving..." : "Resolving..."}
              </span>
            ) : (
              <Button
                size="sm"
                className="d-flex gap-1 align-items-center"
                variant="link text-white"
                onClick={() =>
                  markAsReadUnread({
                    phone: selectedPhoneNumber,
                    status: !hasSeenMessage,
                  })
                }
                disabled={loading}
              >
                {hasSeenMessage ? (
                  <>
                    <CloseCircleFilled className="text-danger" />
                    Mark as Unresolved
                  </>
                ) : (
                  <>
                    <CheckCircleFilled className="text-success" />
                    Mark as Resolved
                  </>
                )}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WhatsappChatScreen;

import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { APP_CHOICES_KEY } from "../../../helpers/constants";
import useLocalization from "../../../hooks/useLocalization";
import { appChoiceService } from "../../../services/appChoiceService";
import AlertModal from "../../common/AlertModal";
import CircularProgressBar from "../../common/circular-progress";

const ArchiveUser = ({ className }) => {
  const { translate } = useLocalization();
  const [showArchivedUser, setShowArchivedUser] = useState(false);
  const [confirmationModalMeta, setConfirmationModalMeta] = useState(null);
  const [fetching, setFetching] = useState(true);

  const toggleSwitch = (checked) => {
    setConfirmationModalMeta({
      ...showArchivedUser,
      values: [
        {
          value: checked,
        },
      ],
    });
  };

  const getArchivedUserSetting = async () => {
    try {
      setFetching(true);
      const reqBody = {
        keys: "appChoices",
        appChoiceFilter: {
          key: APP_CHOICES_KEY.SHOW_ARCHIVE_USERS,
        },
      };
      const { response, error } = await appChoiceService.getAppchoices(reqBody);

      if (error) {
        toast.error(error);
        return;
      }
      const appChoices = response;
      setShowArchivedUser(
        appChoices.find((a) => a.key === APP_CHOICES_KEY.SHOW_ARCHIVE_USERS)
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const handleArchivedUser = async () => {
    try {
      setConfirmationModalMeta({
        ...confirmationModalMeta,
        showProgress: true,
      });

      const { response, error } = await appChoiceService.updateAppchoice(
        confirmationModalMeta
      );

      if (error) {
        setConfirmationModalMeta({
          ...confirmationModalMeta,
          showProgress: false,
        });
        toast.error(error);
        return;
      }
      toast.success("Archive user setting changed succesfully");
      setConfirmationModalMeta(null);
      setShowArchivedUser(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArchivedUserSetting();
  }, []);

  return (
    <Card className={className}>
      <Card.Header>{translate("archive_user")}</Card.Header>
      <Card.Body className="">
        <div className="d-flex">
          <h6 className="smallFont mb-0 flex-grow-1">
            {translate("show_archived_users")}
          </h6>
          {fetching ? (
            <CircularProgressBar />
          ) : (
            <Switch
              checked={showArchivedUser?.values?.[0]?.value}
              onChange={toggleSwitch}
              height={15}
              width={40}
              offHandleColor="#d1e6cc"
              onHandleColor="#d1e6cc"
            />
          )}
        </div>
        <AlertModal
          show={confirmationModalMeta}
          onContinueClick={handleArchivedUser}
          onDismissClick={() => setConfirmationModalMeta(null)}
          onHide={() => setConfirmationModalMeta(null)}
          alertText={translate("confirm_app_config_archive_user", {
            show: showArchivedUser?.values?.[0]?.value ? "hide" : "show",
          })}
        />
      </Card.Body>
    </Card>
  );
};

export default ArchiveUser;

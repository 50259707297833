import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";

import { Plus } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../helpers/api";
import { getEmailTemplatesColumns } from "../../../helpers/dataSheetConstants";
import useLocalization from "../../../hooks/useLocalization";
import AlertModal from "../../common/AlertModal";
import DataTable from "../../common/data-table/DataTable";
import AddEditTemplateModal from "./AddEditTemplateModal";
import { emailTemplateService } from "../../../services/emailTemplateService";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { updateItemsInArray } from "../../../helpers/global";
import ViewTemplateModal from "./ViewTemplateModal";

const defaultSortOption = {
  key: "createdAt",
  order: "desc",
};

export default function EmailTemplates({ className }) {
  const abortControllerRef = useRef(null);

  const [templates, setTemplates] = useState([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(true);
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState(defaultSortOption);
  const { translate } = useLocalization();
  const [showAddModalMeta, setShowAddModalMeta] = useState(null);
  const [deleteTemplateMeta, setDeleteTemplateMeta] = useState(null);
  const [viewTemplateMeta, setViewTemplateMeta] = useState(null);

  const fetchTemplates = async () => {
    try {
      setFetchingTemplates(true);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      const { signal } = controller;

      abortControllerRef.current = controller;

      let requestBody = {
        pageSize: 100,
        pageNumber: 1,
        sort: tableSortOption,
        filter: tableFilterValues,
      };
      let { response, error } = await emailTemplateService.getEmailTemplates(
        requestBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      if (error) {
        toast.error(error);
        return;
      }
      const { results } = response;
      setTemplates(results);
      setFetchingTemplates(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingTemplates(false);
    }
  };

  useDebouncedEffect(
    () => {
      fetchTemplates();
    },
    [tableFilterValues, tableSortOption],
    300
  );

  const onViewTemplate = (template) => {
    setViewTemplateMeta({ template });
  };

  const onTemplateDelete = (template) => {
    setDeleteTemplateMeta({ template });
  };

  const onTemplateUpdate = (template) => {
    setShowAddModalMeta({ template, editMode: true });
  };

  const handleAddUpdateTemplate = async (values) => {
    try {
      const { editMode } = showAddModalMeta;
      setShowAddModalMeta({ ...showAddModalMeta, showProgress: true });

      let { response, error } = await (editMode
        ? emailTemplateService.updateEmailTemplate(values._id, values)
        : emailTemplateService.createEmailTemplate(values));

      if (error) {
        setShowAddModalMeta({ ...showAddModalMeta, showProgress: false });
        toast.error(error);
        return;
      }
      let updatedTemplates = editMode
        ? updateItemsInArray(templates, response)
        : [response, ...templates];
      setTemplates(updatedTemplates);
      setShowAddModalMeta(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTemplate = async (values) => {
    try {
      const { template } = deleteTemplateMeta;
      setDeleteTemplateMeta({ ...deleteTemplateMeta, showProgress: true });

      let { response, error } = await emailTemplateService.deleteEmailTemplates(
        template._id
      );

      if (error) {
        setDeleteTemplateMeta({ ...deleteTemplateMeta, showProgress: false });
        toast.error(error);
        return;
      }
      let updatedTemplates = templates.filter((t) => t._id !== template._id);
      setTemplates(updatedTemplates);
      setDeleteTemplateMeta(null);
    } catch (error) {
      console.log(error);
    }
  };

  const tableColumns = useMemo(
    () =>
      getEmailTemplatesColumns({
        translate,
        onDeleteClick: onTemplateDelete,
        onUpdateClick: onTemplateUpdate,
        onViewTemplate,
      }),
    [translate]
  );

  return (
    <>
      <Card className={className}>
        <Card.Header className="d-flex justify-content-between">
          <span>Email templates ({templates.length || "0"})</span>
          <Button
            size="sm"
            className="d-flex align-items-center px-1 py-0"
            variant="success text-white"
            onClick={() => setShowAddModalMeta({ editMode: false })}
          >
            <Plus />
            <span className="smallFont">{translate("add")}</span>
          </Button>
        </Card.Header>
        <Card.Body className="">
          <DataTable
            rowKey={"_id"}
            sortOptions={tableSortOption}
            onSortChange={setTableSortOption}
            filterValues={tableFilterValues}
            onFilterValuesChange={setTableFilterValues}
            loadingFirstPageData={fetchingTemplates}
            columns={tableColumns}
            data={templates}
          />
        </Card.Body>
      </Card>
      <AddEditTemplateModal
        show={Boolean(showAddModalMeta)}
        onHide={() => setShowAddModalMeta(null)}
        showProgress={showAddModalMeta?.showProgress}
        onSubmit={handleAddUpdateTemplate}
        editMode={showAddModalMeta?.editMode}
        initialValues={showAddModalMeta?.template}
      />{" "}
      <ViewTemplateModal
        show={Boolean(viewTemplateMeta)}
        onHide={() => setViewTemplateMeta(null)}
        template={viewTemplateMeta?.template}
      />
      <AlertModal
        show={Boolean(deleteTemplateMeta)}
        onHide={() => setDeleteTemplateMeta(null)}
        alertText={
          "Are you sure you want to delete this template? This action cannot be undone"
        }
        showProgress={deleteTemplateMeta?.showProgress}
        onContinueClick={handleDeleteTemplate}
        onDismissClick={() => setDeleteTemplateMeta(null)}
      />
    </>
  );
}

import React from "react";
import CustomMultiSelect from "./CustomMultiSelect";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";

const ProductDropdown = ({
  selectedProduct,
  onChange,
  height = "25px",
  maxToShow,
  maxItemCustomMessage,
  isMultiSelect = false,
  closeMenuOnSelect = true,
}) => {
  const units = useAppChoices("units");
  const { translate } = useLocalization();
  return (
    <CustomMultiSelect
      isMulti={isMultiSelect}
      selectedItems={selectedProduct}
      items={units
        .sort((a, b) =>
          (a.description || "").localeCompare(b.description || "")
        )
        ?.map((u) => ({ label: u?.description, value: u?._id }))}
      onChange={onChange}
      placeholder={translate("select_product")}
      height={height}
      closeMenuOnSelect={closeMenuOnSelect}
      maxToShow={maxToShow}
      maxItemCustomMessage={maxItemCustomMessage}
    />
  );
};

export default ProductDropdown;

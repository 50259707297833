import { Client as ConversationsClient } from "@twilio/conversations";
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import WhatsappChatScreen from "../components/common/whatsapp-chat-screen/WhatsappChatScreen";
import { TWILLIO_WHATSAPP_NUMBER } from "../helpers/constants";
import { updateItemsInArray } from "../helpers/global";
import { whatsappConversationService } from "../services/whatsappConversationService";

const convertOnMessageReceived = (message) => {
  const getMessageStatus = (aggregatedDelivery) => {
    if (!aggregatedDelivery) return "sent"; // Fallback for missing aggregatedDelivery

    // Priority order for status
    const statusPriority = [
      "failed",
      "read",
      "delivered",
      "undelivered",
      "sent",
    ];

    // Check each status in priority order
    for (const status of statusPriority) {
      if (aggregatedDelivery[status] === "all") {
        return status; // Return the first matching status
      }
    }

    return "pending"; // Fallback if no status matches
  };

  return {
    sid: message.sid,
    body: message.body || "",
    direction:
      message.author !== `whatsapp:${TWILLIO_WHATSAPP_NUMBER}`
        ? "inbound"
        : "outbound",
    dateSent: message.timestamp || "",
    status: getMessageStatus(message.aggregatedDeliveryReceipt?.state),
  };
};

const convertGetMessages = (message) => {
  return {
    sid: message.sid,
    body: message.body || "",
    direction: message.direction || "",
    dateSent: message.dateSent || "",
    status: message.status,
  };
};

export const WhatsappConversationContext = createContext();

export const WhatsappConversationProvider = ({ children }) => {
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [showChatScreen, setShowChatScreen] = useState(null);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [userWhatsappNumber, setUserWhatsappNumber] = useState(null);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const messageRef = useRef({});

  let localMessage = {};

  const scrollToBottom = () => {
    setTimeout(() => {
      const containerElement = containerRef.current;
      if (containerElement) {
        containerElement.scrollTo({
          top: containerElement.scrollHeight,
        });
      }
    }, 0);
  };

  const scrollToMatchedMessage = (sid) => {
    if (!sid) {
      scrollToBottom();
      return;
    }
    setTimeout(() => {
      const containerElement = containerRef.current;
      if (containerElement && sid) {
        const bubble = messageRef.current[sid].current;
        if (bubble) {
          const bubbleOffsetTop = bubble.offsetTop;
          const containerHeight = containerElement.clientHeight;
          containerElement.scrollTo({
            top: bubbleOffsetTop - containerHeight + bubble.offsetHeight,
          });
        }
      }
    });
  };

  const hasSeenMessage = useMemo(() => {
    if (!showChatScreen || !showChatScreen.contact?.messages) return false;

    return showChatScreen.contact.messages.some(
      (m) => m.phone === userWhatsappNumber && m.seen === true
    );
  }, [showChatScreen, userWhatsappNumber]);

  // 1. Initialize Twilio Client and Fetch Conversation
  useEffect(() => {
    resetConversation();

    const initializeChat = async () => {
      try {
        setFetchingMessages(true);

        if (showChatScreen?.contact?.messages?.length) {
          localMessage = showChatScreen.contact.messages?.find(
            (m) => m.phone === userWhatsappNumber
          );
          if (localMessage) {
            setMessages([
              {
                sid: localMessage.sid,
                body: localMessage.message,
                direction:
                  localMessage.phone === userWhatsappNumber
                    ? "inbound"
                    : "outbound",
                dateSent: localMessage.date,
                status: localMessage.seen ? "read" : "pending",
              },
            ]);
          }
        }

        if (!userWhatsappNumber) return;

        const [conversationResponse, tokenResponse, messagesResponse] =
          await Promise.all([
            whatsappConversationService.getConversation(userWhatsappNumber),
            whatsappConversationService.getClientToken(userWhatsappNumber),
            whatsappConversationService.getMessages({
              phone: userWhatsappNumber,
              pageSize: 1000,
            }),
          ]);

        const { sid } = conversationResponse.response;
        const { token } = tokenResponse.response;
        const { messages } = messagesResponse.response;

        // Initialize Twilio Conversations Client
        const twilioClient = new ConversationsClient(token);

        // Fetch the conversation by SID
        const convo = await twilioClient.getConversationBySid(sid);
        setConversation(convo);

        convo.removeAllListeners();
        // Listen for new messages
        convo.on("messageAdded", (message) => {
          const messageObject = convertOnMessageReceived(message.state);
          setMessages((prev) => updateItemsInArray(prev, messageObject, "sid"));
          setNotifications((pre) => pre + 1);
          scrollToBottom();
        });

        convo.on("messageUpdated", (update) => {
          const message = update.message;
          const messageObject = convertOnMessageReceived(message.state);
          setMessages((prev) => updateItemsInArray(prev, messageObject, "sid"));
        });

        // Update messages state (already fetched in parallel)
        setMessages(messages.map(convertGetMessages));
        setFetchingMessages(false); // All APIs have finished
        scrollToMatchedMessage(localMessage?.sid);
      } catch (error) {
        console.error("Error initializing chat:", error);
        setFetchingMessages(false); // Ensure loading state is updated even on error
        toast.error("Could not load chat, something went wrong");
      }
    };

    initializeChat();
  }, [userWhatsappNumber]);

  // 2. Send Message
  const sendMessage = async () => {
    const msg = input.trim();
    if (conversation && msg) {
      setInput("");
      await whatsappConversationService.sendMessage({
        sid: conversation.sid,
        message: msg,
      });
    }
  };

  const markAsReadUnread = async ({ phone, status }) => {
    setLoading(true);
    const { response } = await whatsappConversationService.markAsReadUnread({
      phone,
      status,
    });
    if (response) {
      setShowChatScreen(null);
      toast.success("Conversation marked as resolved");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showChatScreen) return;
    if (showChatScreen.contact.phoneNumbers[0]) {
      setUserWhatsappNumber(showChatScreen.contact.phoneNumbers[0]);
      setPhoneNumbers(showChatScreen.contact.phoneNumbers);
    }
  }, [showChatScreen]);

  const onPhoneNumberChange = (number) => {
    setUserWhatsappNumber(number);
  };

  const resetConversation = () => {
    setConversation(null);
    setMessages([]);
    setNotifications(0);
  };

  const resetAll = () => {
    setShowChatScreen(null);
    setInput("");
    setConversation(null);
    setMessages([]);
    setMinimized(false);
    setNotifications(0);
    setUserWhatsappNumber(null);
    setPhoneNumbers([]);
  };

  const openWhatsappChatScreen = (contact) => {
    resetAll();
    if (contact) {
      setShowChatScreen({ contact });
    }
  };

  const onCloseChat = () => {
    resetAll();
  };

  const onMinimizeClick = () => {
    setMinimized(true);
    setNotifications(0);
  };
  const onMaximizeClick = () => {
    setMinimized(false);
    setNotifications(0);
    scrollToBottom();
  };
  const contextObj = {
    openWhatsappChatScreen,
  };

  return (
    <WhatsappConversationContext.Provider value={contextObj}>
      {children}
      {showChatScreen && (
        <WhatsappChatScreen
          containerRef={containerRef}
          messageRef={messageRef}
          userName={
            showChatScreen?.contact?.firstName +
            " " +
            showChatScreen?.contact?.lastName
          }
          fetchingMessages={fetchingMessages}
          input={input}
          messages={messages.sort(
            (a, b) => new Date(a.dateSent) - new Date(b.dateSent)
          )}
          sendMessage={sendMessage}
          setInput={setInput}
          onCloseChat={onCloseChat}
          minimized={minimized}
          notifications={notifications}
          onMinimizeClick={onMinimizeClick}
          onMaximizeClick={onMaximizeClick}
          phoneNumbers={phoneNumbers}
          selectedPhoneNumber={userWhatsappNumber}
          onPhoneNumberChange={onPhoneNumberChange}
          hasSeenMessage={hasSeenMessage}
          markAsReadUnread={markAsReadUnread}
          loading={loading}
        />
      )}
    </WhatsappConversationContext.Provider>
  );
};

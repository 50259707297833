import React from "react";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";

const ForgotPasswordModal = ({
  show,
  onHide,
  onSubmit,
  forgotPasswordError,
  showProgress,
  title = "forgot_password",
}) => {
  const { translate } = useLocalization();
  return (
    <AppModal
      size={"md"}
      show={show}
      onHide={onHide}
      title={translate(title)}
      showCloseButton={!showProgress}
    >
      <ForgotPasswordForm
        errorMessage={forgotPasswordError}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default ForgotPasswordModal;

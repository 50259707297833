import React from "react";
import "react-quill/dist/quill.snow.css";
import AppModal from "../../common/AppModal";
import AddEditEmailTemplateForm from "../../forms/AddEditEmailTemplateForm";
const AddEditTemplateModal = ({
  show,
  onHide,
  showProgress,
  onSubmit,
  editMode,
  initialValues = null,
}) => {
  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={editMode ? "Edit template" : "Add template"}
      size={"lg"}
      modalBodyClassName="py-2"
    >
      {/* <h6 className="mid mb-2">
        Use the editor below to design your email template. Add headings, links,
        and rich text formatting to make your email engaging and professional.
      </h6> */}
      <AddEditEmailTemplateForm
        editMode={editMode}
        initialValues={initialValues}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default AddEditTemplateModal;

import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, FormControl, InputGroup } from "react-bootstrap";
import { Check, Check2, Pencil, PlusCircle, X } from "react-bootstrap-icons";
import useLocalization from "../../../../hooks/useLocalization";
import UnderlineButton from "../../../common/UnderlineButton";
import "./styles.scss"; // Ensure to create and import this stylesheet for custom styles.
import CircularProgressBar from "../../../common/circular-progress";
import { snakeCase } from "lodash";

const actions = [
  {
    text: "Event name",
    key: "event.name",
    placeholder: "event_name",
  },
  {
    text: "Event date",
    key: "event.date",
    placeholder: "event_date",
  },
  {
    text: "Event time",
    key: "event.time",
    placeholder: "event_time",
  },
  {
    text: "Webinar link",
    key: "event.webinarLink",
    placeholder: "webinar_link",
  },
];

export default function WhatsappChatScreen({
  isEditing,
  savingMessageBody,
  editable,
  handleEditClick,
  handleSaveClick,
  handleCloseClick,
  message,
  onMessageChange,
  time = "12:45 PM",
  isSent = true,
}) {
  const { isRTL, translate } = useLocalization();
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [message, isEditing]);

  const insertPlaceholder = (placeholder = "") => {
    if (!textareaRef.current) return;
    const { selectionStart, selectionEnd, value } = textareaRef.current;
    const placeholderToAdd = placeholder ? `{{${placeholder}}}` : "{{}}";
    const newText =
      value.substring(0, selectionStart) +
      placeholderToAdd +
      value.substring(selectionEnd);

    onMessageChange(newText);

    const placeholderLength = placeholder ? placeholder.length : 0;
    setTimeout(() => {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.selectionEnd =
        selectionStart + placeholderLength + (placeholder ? 4 : 2);
    }, 0);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "i") {
        event.preventDefault();
        insertPlaceholder();
      }
    };

    const textarea = textareaRef.current;

    if (textarea) {
      textarea.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [message]);

  return (
    <div className="whatsapp-chat-screen-wrapper rounded p-2">
      <div className={"d-flex justify-content-end"}>
        {editable && (
          <div
            className={`d-flex align-items-center flex-wrap justify-content-${
              !isRTL ? "end" : "start"
            } flex-grow-1`}
          >
            {!isEditing ? (
              savingMessageBody ? (
                <div className="d-flex align-items-center gap-1">
                  <CircularProgressBar variant="success" size={12} />{" "}
                  <h6 className="mb-0 text-success smallFont">Saving...</h6>
                </div>
              ) : (
                <UnderlineButton
                  Icon={Pencil}
                  onClick={handleEditClick}
                  text={translate("edit_message")}
                  fontSize="smallFont"
                />
              )
            ) : (
              <>
                <UnderlineButton
                  fontSize="smallFont"
                  Icon={PlusCircle}
                  iconSize={14}
                  variant="dark"
                  onClick={() => insertPlaceholder()}
                  text={translate("insert_placeholder")}
                />
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-dark border-0"
                    size="sm"
                    className="px-1 py-0 mx-1"
                    aria-label="more"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu>
                    {actions.map((action) => (
                      <Dropdown.Item
                        key={action.key}
                        onClick={() => insertPlaceholder(action.placeholder)}
                      >
                        {action.text && (
                          <h6 className="smallFont mb-0">
                            {translate(snakeCase(action.text))}
                          </h6>
                        )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <UnderlineButton
                  fontSize="smallFont"
                  Icon={Check}
                  iconSize={14}
                  variant="success"
                  onClick={handleSaveClick}
                  text={translate("save")}
                />
                <UnderlineButton
                  fontSize="smallFont"
                  iconSize={14}
                  Icon={X}
                  variant="danger"
                  onClick={handleCloseClick}
                  text={translate("close")}
                />
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={`whatsapp-chat-screen px-3 gap-2 justify-content-${
          isRTL ? "start" : "end"
        }`}
      >
        <div className="chat-bubble">
          <div className="chat-bubble-editing-wrapper">
            <textarea
              ref={textareaRef}
              value={message}
              disabled={!isEditing}
              onChange={(e) => onMessageChange(e.target.value)}
              className="chat-bubble-editing"
              rows={1}
            />
          </div>
          <div className="chat-footer">
            <div className="d-flex align-items-center">
              <span className="time-text">{time}</span>
              {isSent && (
                <span className="double-tick">
                  <Check2 size={14} className="tick-icon" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../helpers/api";
import { DEFAULT_PAGE_SIZE } from "../../../helpers/constants";
import { getEventTableColumns } from "../../../helpers/dataSheetConstants";
import {
  getDateWithTimezone,
  getFormattedDate,
  updateItemsInArray,
} from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { eventService } from "../../../services/eventService";
import AlertModal from "../../common/AlertModal";
import FloatingButton from "../../common/FloatingButton";
import DataTable from "../../common/data-table/DataTable";
import AddEditEventModal from "./AddEditEventModal";
import useAppChoices from "../../../hooks/useAppChoices";
import useContactStatus from "../../../hooks/useContactStatus";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseEvents = ({ whatsappTemplates }) => {
  const abortControllerRef = useRef(null);
  const users = useAppChoices("users");
  const { translate } = useLocalization();
  const { getContactStatus } = useContactStatus();
  const [fetchingFirstPageEvents, setFetchingFirstPageEvents] = useState(false);
  const [fetchingMoreEvents, setFetchingMoreEvents] = useState(false);

  //original events array
  const [events, setEvents] = useState([]);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [eventModalMeta, setEventModalMeta] = useState(null);
  const [eventDeleteModalMeta, setEventDeleteModalMeta] = useState(null);

  const onAddNewEventClick = () => {
    setEventModalMeta({});
  };

  const onEditEventClick = (event) => {
    setEventModalMeta({
      initialValues: {
        ...event,
        facebookCampaignIds: event.facebookCampaigns.map((c) => c.id),
        date: getFormattedDate(new Date(event.date)),
      },
      editMode: true,
      editingEvent: event,
    });
  };

  const onDeleteEventClick = (event) => {
    setEventDeleteModalMeta({ event });
  };

  const tableColumns = useMemo(
    () =>
      getEventTableColumns(
        onEditEventClick,
        onDeleteEventClick,
        users,
        translate,
        getContactStatus
      ),
    [onEditEventClick, onDeleteEventClick, users, getContactStatus]
  );

  const fetchEvents = async () => {
    if (!toLoadPageInfo) return;

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const pageToFetch = toLoadPageInfo?.pageNumber;
    const loadingMoreEvents = pageToFetch > 1;

    if (loadingMoreEvents) {
      setFetchingMoreEvents(true);
    } else {
      setFetchingFirstPageEvents(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: 10,
      pageNumber: pageToFetch,
      sort: {
        date: -1,
      },
    };

    const { response, error } = await eventService.getEvents(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreEvents(false);
    setFetchingFirstPageEvents(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [], resultCount } = response;
    setLoadedPageInfo({ totalPages, pageNumber, resultCount });
    setEvents((prevEvents) =>
      loadingMoreEvents ? [...prevEvents, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageEvents || fetchingMoreEvents)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    fetchEvents();
  }, [toLoadPageInfo]);

  const onEventChange = (event) => {
    setEvents((prevEvents) => {
      const updatedEvents = updateItemsInArray(prevEvents, [event]);
      return updatedEvents;
    });
  };

  const onEventSubmit = async (event) => {
    const { editMode, editingEvent } = eventModalMeta;
    setEventModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = editMode
      ? await eventService.updateEvent(editingEvent._id, event)
      : await eventService.createEvent({
          ...event,
          date: getDateWithTimezone(event.date),
        });
    setEventModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setEventModalMeta(null);
    onEventChange(response);
    toast.success(
      editMode ? "Successfully updated event" : "Successfully added event"
    );
  };

  const deleteEvent = async () => {
    const { event } = eventDeleteModalMeta;
    setEventDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = await eventService.deleteEvent(event);
    setEventDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setEventDeleteModalMeta(null);
    setEvents((events) => events.filter((p) => p._id !== event._id));
    toast.success("Successfully deleted event");
  };

  return (
    <>
      <Container fluid className={"px-2 py-3 h-100"}>
        <div className="border rounded p-2 bg-white">
          <h6 className="mb-3 xlarge fw-bold">
            {translate("events")} ({loadedPageInfo?.resultCount || 0}):
          </h6>
          <DataTable
            rowKey={"_id"}
            columns={tableColumns}
            data={events}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreEvents}
            loadingFirstPageData={fetchingFirstPageEvents}
            allowFilter={false}
            allowSort={false}
          />
        </div>
      </Container>
      <FloatingButton
        className="text-white"
        Icon={PlusCircleFill}
        variant="success"
        text={translate("add_new_event")}
        onClick={onAddNewEventClick}
      />
      {Boolean(eventModalMeta) && (
        <AddEditEventModal
          show={Boolean(eventModalMeta)}
          initialValues={eventModalMeta?.initialValues}
          editMode={eventModalMeta?.editMode}
          whatsappTemplates={whatsappTemplates}
          onHide={() => setEventModalMeta(null)}
          showProgress={eventModalMeta?.showProgress}
          onSubmit={onEventSubmit}
        />
      )}

      {events.length > 0 && (
        <AlertModal
          show={Boolean(eventDeleteModalMeta)}
          onHide={() => setEventDeleteModalMeta(false)}
          onDismissClick={() => setEventDeleteModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_events", {
            eventName: eventDeleteModalMeta?.event?.name,
          })}
          showProgress={eventDeleteModalMeta?.showProgress}
          onContinueClick={deleteEvent}
        />
      )}
    </>
  );
};

export default BrowseEvents;

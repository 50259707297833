import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SendArrowUp } from "react-bootstrap-icons";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import { templateUrl } from "../../../helpers/global";
import { whatsappCampaignService } from "../../../services/whatsappCampaignService";
import { whatsappTemplateService } from "../../../services/whatsappTemplateService";
import AlertModal from "../../common/AlertModal";
import useAuth from "../../../hooks/useAuth";
import { isAdminOrManager } from "../../../helpers/session";
import { WHATSAPP_SOURCE_QUICK_SEND } from "../../../helpers/constants";
import OverviewWhatsappTemplate from "../../common/OverviewWhatsappTemplate";

const SalesPersonWhatsappTemplates = ({ contact }) => {
  const { user } = useAuth();
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [fetchingWhatsappTemplates, setFetchingWhatsappTemplates] =
    useState(true);
  const [confirmSentWhatsappMessage, setConfirmSentWhatsappMessage] =
    useState(null);

  const fetchTemplates = async () => {
    setFetchingWhatsappTemplates(true);

    let requestBody = {
      filter: { status: "Approved", category: "salesperson" },
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody);

    setFetchingWhatsappTemplates(false);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
  };

  const sendTemplate = (template) => {
    if (!template || !contact) return;

    setConfirmSentWhatsappMessage({ template, contact });
  };

  const sendWhatsappMessage = async () => {
    const { template } = confirmSentWhatsappMessage;

    setConfirmSentWhatsappMessage({
      ...confirmSentWhatsappMessage,
      showProgress: true,
    });
    let requestBody = {
      selectedContacts: [contact?._id],
      allSchedules: [
        {
          template: template._id,
          trigger: "send_now",
        },
      ],
      sourceType: WHATSAPP_SOURCE_QUICK_SEND,
    };

    let { response, error } =
      await whatsappCampaignService.createWhatsappCampaign(requestBody);
    if (error) {
      toast.error(error);
      setConfirmSentWhatsappMessage({
        ...confirmSentWhatsappMessage,
        showProgress: false,
      });
      return;
    }
    toast.success("Whatsapp message sent succesfully");
    setConfirmSentWhatsappMessage(null);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return fetchingWhatsappTemplates ? (
    <ContentLoader width="80%">
      <rect x="0" y="50" rx="3" ry="3" width="60%" height="15" />
      <rect x="0" y="70" rx="3" ry="3" width="50%" height="10" />
    </ContentLoader>
  ) : (
    <div className="d-flex align-items-center gap-1">
      {whatsappTemplates.length > 0 ? (
        whatsappTemplates.map((template) => (
          <div
            key={template._id}
            className="border border-dark rounded p-1 d-flex gap-2 align-items-center"
          >
            <OverviewWhatsappTemplate
              message={template?.messageBody}
              Element={(props) =>
                isAdminOrManager(user.role) ? (
                  <a
                    target="_blank"
                    className="smallFont"
                    {...props}
                    href={templateUrl(template)}
                  >
                    {template?.templateName}
                  </a>
                ) : (
                  <h6 {...props} className="smallFont mb-0">
                    {template?.templateName}
                  </h6>
                )
              }
            />

            <Button
              className="py-0"
              variant="success text-white"
              size="sm"
              onClick={() => sendTemplate(template)}
            >
              <SendArrowUp className="" />
            </Button>
          </div>
        ))
      ) : (
        <h6 className="mid mx-2 mb-0 text-secondary">No templates found</h6>
      )}

      <AlertModal
        show={Boolean(confirmSentWhatsappMessage)}
        onHide={() => setConfirmSentWhatsappMessage(null)}
        onDismissClick={() => setConfirmSentWhatsappMessage(null)}
        showProgress={confirmSentWhatsappMessage?.showProgress}
        onContinueClick={sendWhatsappMessage}
        alertText={`Are you sure to send ${confirmSentWhatsappMessage?.template?.templateName} message to ${contact?.firstName}?`}
      />
    </div>
  );
};

export default SalesPersonWhatsappTemplates;

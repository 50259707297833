import React from "react";
import Loader from "./Loader";

const HorizontalTabs = ({
  loading,
  tabs = [],
  selectedTab,
  onTabChange,
  height = 400,
}) => {
  return (
    <div
      className="d-flex flex-column w-100 gap-1 custom-card p-2"
      style={{ minHeight: height, maxHeight: height }}
    >
      {loading ? (
        <Loader />
      ) : (
        tabs.map((tab, index) => {
          const { icon: Icon, label = "", data, key, variant } = tab;
          const isSelected = selectedTab === key;
          return (
            <div
              key={key}
              className={`d-flex align-items-center justify-content-between hover p-2 rounded  ${
                isSelected ? `bg-primary-light` : ""
              }`}
              onClick={() => onTabChange(key)}
            >
              <div className="d-flex gap-2 align-items-center large">
                {Icon && <Icon className={`text-${variant}`} />} {label}
              </div>
              {data && (
                <div
                  className={`large  fw-bold ${
                    isSelected ? `text-${variant}` : `text-secondary`
                  }`}
                >
                  {data}
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default HorizontalTabs;

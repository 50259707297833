import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const emailTemplateService = {
  getEmailTemplates: async (reqBody = {}, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_EMAIL_TEMPLATES,
      requestBody: reqBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  createEmailTemplate: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_EMAIL_TEMPLATE,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  updateEmailTemplate: async (templateID, reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.EMAIL_TEMPLATE_WITH_ID(templateID),
      requestBody: reqBody,
      method: "PUT",
    });

    return { response, error };
  },
  deleteEmailTemplates: async (templateID) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.EMAIL_TEMPLATE_WITH_ID(templateID),
      method: "DELETE",
    });

    return { response, error };
  },
};

import React from "react";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";
import DateRangePicker from "./DateRangePicker";
import UserDropdown from "./UserDropdown";
import useLocalization from "../../hooks/useLocalization";
import ProductDropdown from "./ProductDropdown";

const UserAndDateRangePicker = ({
  selectedUser = [],
  onUserChange,
  dateRange,
  onDateRangeChange,
  selectedProduct = [],
  onProductChange,
  className = "d-flex justify-content-end flex-wrap gap-2",
  hideProductFilter = false,
  hideUserFilter = false,
  hideDateFilter = false,
}) => {
  const { isRTL } = useLocalization();
  const { user } = useAuth();
  return (
    <div className={className}>
      {isAdminOrManager(user?.role) && (
        <>
          {!hideProductFilter && (
            <div style={{ minWidth: 150, maxWidth: 350 }} className="">
              <ProductDropdown
                selectedProduct={selectedProduct}
                onChange={onProductChange}
                maxItemCustomMessage={(length) => `+ ${length} more`}
                height="26px"
                maxToShow={5}
                isMultiSelect
                closeMenuOnSelect={false}
              />
            </div>
          )}
          {!hideUserFilter && (
            <div style={{ minWidth: 150, maxWidth: 350 }} className="">
              <UserDropdown
                selecteduser={selectedUser}
                onChange={onUserChange}
                maxItemCustomMessage={(length) => `+ ${length} more`}
                height="26px"
                maxToShow={5}
                isMultiSelect
                closeMenuOnSelect={false}
              />
            </div>
          )}
        </>
      )}
      {!hideDateFilter && (
        <DateRangePicker
          value={dateRange}
          onChange={(ranges) => {
            onDateRangeChange(ranges);
          }}
          className=""
          isRTL={isRTL}
        />
      )}
    </div>
  );
};

export default UserAndDateRangePicker;

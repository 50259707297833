import { useContext } from "react";
import { WhatsappConversationContext } from "../context/WhatsappConversationContext";

const useWhatsappConversation = () => {
  const { openWhatsappChatScreen } = useContext(WhatsappConversationContext);

  return {
    openWhatsappChatScreen,
  };
};

export default useWhatsappConversation;

import React from "react";
import AppModal from "../../common/AppModal";
import AddEditEmailTemplateForm from "../../forms/AddEditEmailTemplateForm";
const ViewTemplateModal = ({ show, onHide, template = null }) => {
  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={template?.name}
      size={"lg"}
      modalBodyClassName="py-2"
    >
      <div>
        <h6 style={{ fontSize: 24, fontWeight: 500 }} className="mb-4">
          {template?.subject}
        </h6>
        <div
          className="large"
          dangerouslySetInnerHTML={{
            __html: template?.body.replace(/<p><br><\/p>/g, ""),
          }}
        />
      </div>
    </AppModal>
  );
};

export default ViewTemplateModal;

import React, { useEffect, useState } from "react";
import AppModal from "../../common/AppModal";
import WhatsappTemplateTable from "./WhatsappTemplateTable";
import { Button } from "react-bootstrap";
import useLocalization from "../../../hooks/useLocalization";
import Loader from "../../common/Loader";
import useWhatsappCampaigns from "../../../hooks/useWhatsappCampaigns";
import { updateItemsInArray } from "../../../helpers/global";
import { inboundSourceDefaultsStatusService } from "../../../services/inboundSourceDefaultsStatusService";
import { toast } from "react-toastify";
import HorizontalProgress from "../../common/HorizontalProgress";
import { WHATSAPP_SOURCE_INBOUNDS } from "../../../helpers/constants";

const AddEditWhatsappTemplateModal = ({
  show,
  onHide,
  updatingException,
  whatsappTemplates,
  inbound,
}) => {
  const { translate } = useLocalization();
  const [value, setValue] = useState([]);
  const [errors, setErrors] = useState(null);
  const [updatingExceptions, setUpdatingExceptions] = useState(false);

  const { campaigns = [], isLoadingWhatsappCampaign } = useWhatsappCampaigns({
    source: WHATSAPP_SOURCE_INBOUNDS,
    sourceId: inbound?._id,
    exceptionId: updatingException?._id,
  });

  useEffect(() => {
    if (updatingException) {
      setValue(campaigns);
    }
  }, [campaigns, updatingException]);

  const onDefaultStatusSubmit = async () => {
    setUpdatingExceptions(true);
    const updatedException = { ...updatingException, whatsappCampaigns: value };
    const requestBody = {
      _id: inbound._id,
      exceptions: updateItemsInArray(
        inbound?.exceptions?.map(
          ({ whatsappCampaigns, ...exception }) => exception
        ),
        updatedException,
        "_id"
      ),
    };
    const { response, error } =
      await inboundSourceDefaultsStatusService.updateInboundSourceDefaultsStatus(
        requestBody
      );

    if (error) {
      toast.error(error);
      setUpdatingExceptions(false);
      return;
    }
    toast.success("Exception updated successfully");
    setUpdatingExceptions(false);
    onHide();
  };

  const handleSubmit = () => {
    if (value.find((v) => !v.template)) {
      setErrors("Template cannot be empty");
    } else {
      setErrors(null);
      onDefaultStatusSubmit();
    }
  };

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={"Update whatsapp campaign"}
      modalBodyClassName="p-1"
    >
      {isLoadingWhatsappCampaign ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="p-1">
            <hb className="smallFont">Whatsapp campaigns</hb>
            <WhatsappTemplateTable
              values={value}
              whatsappTemplates={whatsappTemplates}
              onChange={setValue}
              errors={[]}
            />
          </div>
          {updatingExceptions && <HorizontalProgress text={"Please wait..."} />}
          <div className="d-flex gap-1 justify-content-end align-items-center border-top p-1">
            {" "}
            <Button variant="dark" onClick={onHide} size="sm">
              {translate("close")}
            </Button>
            <Button
              variant="success text-white"
              onClick={handleSubmit}
              size="sm"
            >
              {translate("save")}
            </Button>
          </div>
        </>
      )}
    </AppModal>
  );
};

export default AddEditWhatsappTemplateModal;

import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const dashboardService = {
  getStats: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_STATS,
      requestBody,
      signal,
    });

    return { response, error };
  },

  getMarketingStats: async ({ startDate, endDate }, signal, salesperson) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint:
        !startDate || !endDate
          ? ENDPOINTS.GET_MARKETING_STATS_NO_FILTER
          : ENDPOINTS.GET_MARKETING_STATS(startDate, endDate, salesperson),
      method: "GET",
      signal,
    });

    return { response, error };
  },
};

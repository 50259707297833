import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { CustomEvent } from "../common/CustomEvent";
import DraggableModal from "../common/DraggableModal";
import GoogleEventBadge from "../common/GoogleEventBadge";
import AddEditAppointmentForm from "../forms/add-edit-appointment-form/AddEditAppointmentForm";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import { toast } from "react-toastify";

const AllAppointments = ({ appointments = [], onAppointmentClick }) => {
  return (
    <div className="overflow-auto px-2 d-flex flex-column gap-1">
      {appointments?.map((appointment, index) => {
        return (
          <div
            onClick={() => onAppointmentClick(appointment)}
            className="hover"
            key={appointment._id}
          >
            <CustomEvent markPastEvents event={appointment} />
          </div>
        );
      })}
    </div>
  );
};

const AddEditAppointmentModal = ({
  show,
  onHide,
  onBackToShowMore,
  showMoreMode,
  allAppointments,
  onAppointmentClick,
  appointment = null,
  showProgress,
  defaultInviteesEmail,
  appointmentMetaDataByType,
  editMode,
  disabled,
  onSubmit,
  onAppointmentDelete,
  contact,
  contacts,
  specificContactMode,
  setAppointmentMetadata,
  popupOffset = null,
  showBackToShowMoreButton,
}) => {
  if (!show) return;
  const { message, clearMessage } = useWebSocket();
  const { translate, isRTL } = useLocalization();
  const { screenWidth } = useScreenWidth();
  const [updatingAppointment, setUpdatingAppointment] = useState(null);

  useEffect(() => {
    if (appointment) {
      setUpdatingAppointment(appointment);
    }
  }, [appointment]);

  useEffect(() => {
    if (message) {
      let { type, payload } = message;
      switch (type) {
        case BROADCAST_TYPES.APPOINTMENT_UPDATE:
          if (payload._id === updatingAppointment._id) {
            toast.info(
              "This appointment was updated by someone. You are viewing latest changes."
            );
            setUpdatingAppointment({
              ...payload,
              date: moment(payload.startDateTime).format("YYYY-MM-DD"),
              start: moment(payload.startDateTime).format("HH:mm"),
              end: moment(payload.endDateTime).format("HH:mm"),
              isAppEvent: true,
            });
            clearMessage();
          }
          break;
        case BROADCAST_TYPES.APPOINTMENT_DELETE:
          if (payload._id === updatingAppointment._id) {
            toast.info("This appointment is deleted by someone.");
            onHide();
          }
          break;
        default:
          console.log("Unknown message type");
          break;
      }
    }
  }, [message]);

  const modalHeight = useMemo(() => {
    if (!showMoreMode) return 400;
    return allAppointments.length * 23 + (allAppointments.length - 1) * 4 + 50;
  }, [showMoreMode, allAppointments]);

  return (
    <DraggableModal
      hasOverlay
      show={show}
      onHide={onHide}
      title={
        showMoreMode
          ? moment(allAppointments[allAppointments.length - 1].start).format(
              "MMMM Do YYYY"
            )
          : translate(editMode ? "edit_appointment" : "new_appointment")
      }
      width={{
        sm: screenWidth - 20,
        md: showMoreMode ? 250 : 480,
        lg: showMoreMode ? 250 : 480,
      }}
      height={modalHeight}
      popupOffset={popupOffset}
      HeaderExtraComponent={
        !showMoreMode && editMode && !updatingAppointment?.isAppEvent
          ? GoogleEventBadge
          : null
      }
      LeftHeaderExtraComponent={
        showBackToShowMoreButton
          ? () => (
              <Button
                size="sm"
                variant="outline-dark"
                className="d-flex align-items-center p-1 border-0"
                onClick={onBackToShowMore}
              >
                <ChevronLeft />
              </Button>
            )
          : null
      }
    >
      {showMoreMode ? (
        <AllAppointments
          appointments={allAppointments}
          onAppointmentClick={onAppointmentClick}
        />
      ) : (
        <AddEditAppointmentForm
          contact={contact}
          contacts={contacts}
          formValues={updatingAppointment}
          disabled={disabled}
          defaultInviteesEmail={defaultInviteesEmail}
          appointmentMetaDataByType={appointmentMetaDataByType}
          editMode={editMode}
          onSubmit={onSubmit}
          onAppointmentDelete={onAppointmentDelete}
          showProgress={showProgress}
          specificContactMode={specificContactMode}
          setAppointmentMetadata={setAppointmentMetadata}
        />
      )}
    </DraggableModal>
  );
};

export default AddEditAppointmentModal;

import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userService } from "../services/userService";

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { response, error } = await userService.getUsers();
        if (error) {
          toast.error(error);
          setUsers([]);
          return;
        }
        const { results } = response;
        setUsers(results);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);

  const mutipalUserUpdate = async (requestBody) => {
    const { response, error } = await userService.updateMultipleUser(
      requestBody
    );
    setUsers(response);
    if (error) {
      return toast.error(error);
    }
  };

  const contextObj = {
    users,
    mutipalUserUpdate,
  };

  return (
    <UsersContext.Provider value={contextObj}>{children}</UsersContext.Provider>
  );
};

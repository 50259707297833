import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const whatsappConversationService = {
  getConversation: async (phone) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_CREATE_CONVERSATIONS(phone),
      method: "GET",
    });

    return { response, error };
  },
  getClientToken: async (ssid) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_CLIENT_TOKEN,
      method: "GET",
    });

    return { response, error };
  },

  sendMessage: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_MESSAGE,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  getMessages: async ({ phone, pageSize }) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_MESSAGES({ phone, pageSize }),
      method: "GET",
    });

    return { response, error };
  },
  markAsReadUnread: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.MARK_AS_READ_UNREAD,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
};

import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Row, Spinner } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useLocalization from "../../hooks/useLocalization";
import { userService } from "../../services/userService";
import Logo from "../common/Logo";
import ConfirmPasswordForm from "../forms/ConfirmPasswordForm";
import ForgotPasswordModal from "./ForgotPasswordModal";

const ResetPassword = () => {
  const { translate } = useLocalization();
  const navigate = useNavigate();
  const { key } = useParams();
  const [findingUser, setFindUser] = useState(true);
  const [resetingPassword, setResetingPassword] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState(null);

  const findUser = async () => {
    try {
      setFindUser(true);
      const { response, error } = await userService.getUserByID(key);
      if (response) {
        setUser(response);
      }
      if (error) {
        setErrorMessage({
          type: "user",
          message: error,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFindUser(false);
    }
  };

  useEffect(() => {
    if (key) {
      findUser();
    }
  }, [key]);

  const createPassword = async ({ password }) => {
    try {
      setResetingPassword(true);
      const { response, error } = await userService.resetPassword({
        email: user?.email,
        resetPasswordKey: key,
        password,
      });
      if (response) {
        toast.success(
          "Password updated successfully. You can login using your new password"
        );
        navigate("/auth/login");
      }
      if (error) {
        setErrorMessage({
          type: "createPassword",
          message: error,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResetingPassword(false);
    }
  };

  const onForgotPasswordSubmit = async (data) => {
    try {
      setShowForgotPassModal({ showProgress: true });
      const { response, error } = await userService.forgotPassword(data);
      if (response) {
        toast.success("Password reset link has been sent");
        setShowForgotPassModal(null);
      }
      if (error) {
        setShowForgotPassModal({ showProgress: false, errorMessage: error });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="justify-content-center h-100">
      <Col xs={10} md={6} className="align-self-center">
        <Card className="">
          <Card.Header className="p-3 bg-dark">
            <div className="">
              <Logo />
            </div>
          </Card.Header>
          <Card.Body>
            {findingUser || errorMessage?.type === "user" ? (
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                {findingUser ? (
                  <Spinner />
                ) : (
                  <>
                    <Alert
                      className="p-4 mt-3 large d-flex align-items-center gap-3"
                      variant="danger"
                    >
                      <InfoCircleFill size={40} />
                      {translate("token_expired_invalid")}
                    </Alert>
                  </>
                )}
              </div>
            ) : (
              <>
                {" "}
                <h6 className="text-muted mx-2 my-1">
                  {translate("hello")} <strong>{user?.name}, </strong>
                  {translate("welcome_to_ETICA_crm")}
                </h6>
                <h6 className="xxlarge mx-2 my-1">
                  <b className="text-dark">
                    {translate("please_reset_your_password")}
                  </b>
                </h6>
                <hr />
                <ConfirmPasswordForm
                  loading={resetingPassword}
                  errorMessage={
                    errorMessage?.type === "createPassword"
                      ? errorMessage?.message
                      : null
                  }
                  onSubmit={createPassword}
                />
              </>
            )}
            {!findingUser && (
              <>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <Link
                    className="text-dark large d-block text-center"
                    to={"/auth/login"}
                  >
                    {translate("go_to_login_page")}
                  </Link>
                  {!user && (
                    <>
                      <Link
                        className="text-dark large d-block text-center"
                        onClick={() =>
                          setShowForgotPassModal({ showProgress: false })
                        }
                      >
                        Resend password reset link
                      </Link>
                      <ForgotPasswordModal
                        title={"resend_password_link"}
                        show={showForgotPassModal}
                        onHide={() => setShowForgotPassModal(null)}
                        showProgress={showForgotPassModal?.showProgress}
                        onSubmit={onForgotPasswordSubmit}
                        forgotPasswordError={showForgotPassModal?.errorMessage}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ResetPassword;

import React, { useEffect, useRef } from "react";
import { Chart, LinearScale, CategoryScale } from "chart.js";
import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(
  FunnelController,
  TrapezoidElement,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);

const FunnelChart = ({ data, title, aspectRatio }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");

    const datasets = data.datasets;
    const labels = datasets.map((dataset) => dataset.label);
    const values = datasets.map((dataset) => dataset.data[0]);
    const backgroundColors = datasets.map((dataset) => dataset.backgroundColor);

    const chartData = {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: backgroundColors,
        },
      ],
    };

    const chart = new Chart(ctx, {
      type: "funnel",
      data: chartData,
      options: {
        indexAxis: "y",
        aspectRatio,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              generateLabels: (chart) => {
                return chartData.labels.map((label, index) => ({
                  text: label,
                  fillStyle: chartData.datasets[0].backgroundColor[index],
                  hidden: false,
                  index: index,
                }));
              },
            },
          },
          datalabels: {
            display: true,
            color: "#fff",
            font: {
              size: 12,
            },
            formatter: (value, ctx) => {
              return `${values[ctx.dataIndex]}`;
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        scales: {
          y: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="custom-card">
      <h6 className="large fw-bold mb-2">{title}</h6>
      <canvas ref={canvasRef} width="400" height="400" />
    </div>
  );
};

export default FunnelChart;

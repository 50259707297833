import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { miscService } from "../../services/miscService";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";
import Loader from "../common/Loader";
import BrowseAutomations from "./automation/BrowseAutomations";
import BrowseEvents from "./events/BrowseEvents";
import InboundSourceDefaultStatus from "./inbound-source-status/InboundSourceDefaultStatus";
import useActiveTrailAndFacebookCampaign from "../../hooks/useActiveTrailAndFacebookCampaign";

const AutomationAndEvents = () => {
  const { fetchingActiveTrailAndCampaigns } =
    useActiveTrailAndFacebookCampaign();

  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [fetchingWhatsappTemplates, setFetchingWhatsappTemplates] =
    useState(true);

  const fetchTemplates = async () => {
    setFetchingWhatsappTemplates(true);

    let requestBody = {
      filter: { status: "Approved" },
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
    setFetchingWhatsappTemplates(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return fetchingWhatsappTemplates || fetchingActiveTrailAndCampaigns ? (
    <div className="px-3">
      <Loader />
    </div>
  ) : (
    <div className="pb-5 position-relative">
      <BrowseAutomations whatsappTemplates={whatsappTemplates} />
      <BrowseEvents whatsappTemplates={whatsappTemplates} />
      <InboundSourceDefaultStatus whatsappTemplates={whatsappTemplates} />
    </div>
  );
};

export default AutomationAndEvents;

import { startCase } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  ArrowRightCircleFill,
  CalendarEventFill,
  CameraVideoFill,
  Diagram2Fill,
  Facebook,
  FunnelFill,
  Globe,
  PersonPlusFill,
  QuestionCircleFill,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../../helpers/api";
import { getInboundTableColumns } from "../../../../helpers/dataSheetConstants";
import {
  convertToFunnelChartData,
  getDashboardLineCartData,
} from "../../../../helpers/global";
import useLocalization from "../../../../hooks/useLocalization";
import { dashboardService } from "../../../../services/dashboardService";
import DashboardDataNavigator from "../../../common/DashboardDataNavigator";
import DataTable from "../../../common/data-table/DataTable";
import LineChart from "../../../common/graph/LineChart";
import HorizontalTabs from "../../../common/HorizontalTabs";
import Loader from "../../../common/Loader";
import MarketingTable from "../../MarketingTable";
import StatCard from "../../StatCard";
import FunnelChart from "./FunnelChart";

const funnelProgressColumns = () => [
  {
    key: "ratio",
    label: "Ratio %",
    disableSearch: true,
    disableSort: true,
  },
  {
    key: "amount",
    label: "Amount (of change)",
    disableSearch: true,
    disableSort: true,
  },
  {
    key: "status",
    label: "Status",
    disableSearch: true,
    disableSort: true,
  },
];

const MarketingStatBoard = ({
  rangeType,
  dateRangeLabel,
  handleNavigateDate,
  navigating,
  dateRange,
  selectedUser,
}) => {
  const abortControllerRef = useRef(null);
  const { translate } = useLocalization();
  const [activeStat, setActiveStat] = useState(null);
  const [activeSecondaryStat, setActiveSecondaryStat] = useState("webinar");

  const [marketingStatsData, setMarketingStats] = useState([]);
  const [initialFetching, setInitialFetching] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [isInitialFetching, setIsInitialFetching] = useState(true);

  useEffect(() => {
    const fetchMarketingStats = async () => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        if (isInitialFetching) {
          setInitialFetching(true);
        } else {
          setFetchingMore(true);
        }

        const controller = new AbortController();
        const { signal } = controller;

        abortControllerRef.current = controller;

        let { response, error } = await dashboardService.getMarketingStats(
          dateRange,
          signal,
          selectedUser
        );
        if (error === ABORT_ERROR) return;
        if (response) {
          setMarketingStats(response);
        }
        if (error) {
          toast.error(error);
        }
      } catch (error) {
        console.error("Error fetching marketing stats:", error);
      } finally {
        setInitialFetching(false);
        setFetchingMore(false);
        setIsInitialFetching(false);
      }
    };

    fetchMarketingStats();
  }, [dateRange, selectedUser]);

  const marketingStats = useMemo(() => {
    return [
      {
        name: "inbounds",
        variant: "primary",
        icon: PersonPlusFill,
        data: marketingStatsData?.data?.inbounds?.totalCount,
      },
      {
        name: "leads",
        data: marketingStatsData?.data?.leads?.totalCount,
        variant: "secondary",
        icon: Diagram2Fill,
      },
      {
        name: "events",
        data: "-",
        variant: "info",
        icon: CalendarEventFill,
      },
      {
        name: "funnel",
        data: "-",
        variant: "dark",
        icon: FunnelFill,
      },
    ];
  }, [marketingStatsData]);

  const leadSourceConfig = useMemo(() => {
    const data = marketingStatsData?.data?.[activeStat?.name]?.details;
    if (!data) return [];
    const chartInterval = marketingStatsData?.data?.inbounds?.chartInterval;
    return [
      {
        icon: CameraVideoFill,
        variant: "success",
        label: "Webinar",
        key: "webinar",
        data: data?.webinar?.count,
        chartData: getDashboardLineCartData(
          data?.webinar?.chartData,
          "Webinar",
          chartInterval
        ),
        tableData: data?.webinar?.tableData,
      },
      {
        icon: Globe,
        variant: "primary",
        label: "Website",
        key: "website",
        data: data?.website?.count,
        chartData: getDashboardLineCartData(
          data?.website?.chartData,
          "Website",
          chartInterval
        ),
        tableData: data?.website?.tableData,
      },
      {
        icon: QuestionCircleFill,
        variant: "purple",
        label: "Other",
        key: "other",
        data: data?.other?.count,
        chartData: getDashboardLineCartData(
          data?.other?.chartData,
          "Other",
          chartInterval
        ),
        tableData: data?.other?.tableData,
      },
      {
        icon: ArrowRightCircleFill,
        variant: "warning",
        label: "Direct",
        key: "direct",
        data: data?.direct?.count,
        chartData: getDashboardLineCartData(
          data?.direct?.chartData,
          "Direct",
          chartInterval
        ),
        tableData: data?.direct?.tableData,
      },
      {
        icon: Facebook,
        variant: "info",
        label: "Facebook",
        key: "facebook",
        data: data?.facebook?.count,
        chartData: getDashboardLineCartData(
          data?.facebook?.chartData,
          "Facebook",
          chartInterval
        ),
        tableData: data?.facebook?.tableData,
      },
    ];
  }, [marketingStatsData, activeStat]);

  const activeTabData = useMemo(
    () => leadSourceConfig.find((c) => c.key === activeSecondaryStat),
    [leadSourceConfig, activeSecondaryStat]
  );

  useEffect(() => {
    setActiveStat(
      !activeStat
        ? marketingStats?.[0]
        : marketingStats?.find((s) => s?.name === activeStat?.name)
    );
  }, [marketingStats]);

  const handleCardClick = (stat) => {
    setActiveStat(stat);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Row className="my-2 mx-1">
        {marketingStats?.map((stat, index) => (
          <Col className="p-1" key={index} lg={3} md={3} sm={12} xs={12}>
            <StatCard
              stat={stat}
              active={activeStat?.name === stat?.name}
              fetching={initialFetching}
              onClick={handleCardClick}
            />
          </Col>
        ))}
      </Row>
      <hr className="my-1" />
      {["leads", "inbounds"].includes(activeStat?.name) && (
        <Row className="pt-2 m-0">
          <Col lg={3} md={3} sm={12} xs={12} className="p-0">
            <div className="d-flex flex-column gap-2">
              <HorizontalTabs
                loading={initialFetching}
                tabs={leadSourceConfig}
                onTabChange={setActiveSecondaryStat}
                selectedTab={activeSecondaryStat}
                height={230}
              />
              {activeStat?.name === "leads" && (
                <>
                  {marketingStatsData.data.funnelProgress && (
                    <FunnelChart
                      data={convertToFunnelChartData(
                        marketingStatsData.data.funnelProgress,
                        "Conversion ratio"
                      )}
                      title={"Progress"}
                      aspectRatio={1}
                    />
                  )}{" "}
                  {marketingStatsData.data.funnelRatios && (
                    <div className="custom-card">
                      <h6 className="large fw-bold">Ratios</h6>
                      <DataTable
                        rowKey={"_id"}
                        columns={funnelProgressColumns()}
                        data={Object.entries(
                          marketingStatsData.data.funnelRatios
                        ).map(([status, ratio], index) => ({
                          status: startCase(status),
                          ratio,
                          amount:
                            marketingStatsData?.data?.funnelProgress[status] ??
                            0,
                          _id: index,
                        }))}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
          <Col>
            {" "}
            {initialFetching ? (
              <div className="custom-card">
                <Loader />
              </div>
            ) : (
              <div className="d-flex flex-column gap-2">
                {(!activeTabData ||
                  !activeTabData.chartData ||
                  !activeTabData.tableData) && (
                  <div className="custom-card d-flex align-items-center pt-2">
                    <h6 className="smallFont mt-2">
                      No any data found for this lead source
                    </h6>
                  </div>
                )}
                {activeTabData && activeTabData.chartData && (
                  <LineChart
                    chartData={activeTabData.chartData}
                    chartName={activeSecondaryStat}
                    loading={initialFetching}
                    randerExtraInfo={() => (
                      <DashboardDataNavigator
                        rangeType={rangeType}
                        dateRangeLabel={dateRangeLabel}
                        handleNavigateDate={handleNavigateDate}
                        loading={fetchingMore}
                      />
                    )}
                    hideContiniousFalsyDataFromLast
                  />
                )}{" "}
                {activeTabData && activeTabData.tableData && (
                  <div className="custom-card">
                    <h6 className="xxlarge fw-bold">
                      {startCase(activeSecondaryStat)}
                    </h6>
                    <DataTable
                      columns={getInboundTableColumns({
                        columnToHide: ["campaign", "details"],
                      })}
                      data={activeTabData.tableData}
                      maxTableHeight={`400px`}
                      rowKey={"date"}
                      allowSort={false}
                      allowFilter={false}
                    />
                  </div>
                )}{" "}
              </div>
            )}
          </Col>
        </Row>
      )}
      {activeStat?.name === "events" && <MarketingTable />}
      {activeStat?.name === "funnel" && (
        <Row className="pt-2 m-0">
          <Col lg={6} md={6} sm={12} xs={12} className="p-0">
            <FunnelChart
              data={convertToFunnelChartData(
                marketingStatsData.data.funnelProgress,
                "Conversion ratio"
              )}
              title={"Progress"}
              aspectRatio={1.5}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MarketingStatBoard;

import { useContext, useEffect, useState } from "react";
import AppChoicesContext from "../context/AppChoicesContext";

const useAppChoices = (key, returnDisabledValuesAlso = true) => {
  const appChoices = useContext(AppChoicesContext);
  // Using useState to maintain a local state for the choices to return
  const [choicesToReturn, setChoicesToReturn] = useState([]);

  useEffect(() => {
    // This function processes the choices based on the provided key and flag returnDisabledValuesAlso
    const processChoices = () => {
      let processedChoices = key
        ? appChoices
            ?.find((choice) => choice.key === key)
            ?.values.map((value) => value)
        : appChoices?.map((appChoice) => ({
            ...appChoice,
            values: appChoice?.values.map((value) => value),
          }));

      if (
        key &&
        !returnDisabledValuesAlso &&
        processedChoices[0] &&
        typeof processedChoices[0] === "object" &&
        processedChoices[0].hasOwnProperty("isEnabled")
      ) {
        processedChoices = processedChoices?.filter((c) => c.isEnabled) || [];
      }

      return processedChoices || [];
    };

    // Set the state with the processed choices
    setChoicesToReturn(processChoices());
  }, [appChoices, key, returnDisabledValuesAlso]); // useEffect will re-run if any of these dependencies change

  return choicesToReturn;
};

export default useAppChoices;

import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";
import SendWhatsappCampaignForm from "../forms/SendWhatsappCampaignForm";
import AppModal from "./AppModal";
import Loader from "./Loader";

const CreateWhatsappCampaignModal = ({
  show,
  onHide,
  showProgress,
  onSubmit,
  allowOnSignUp = false,
  loading = false,
  editableSource = false,
  events = [],
  automations = [],
  inboundSource = [],
  initialValues,
  editMode,
}) => {
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [fetchingWhatsappTemplates, setFetchingWhatsappTemplates] =
    useState(true);

  const fetchTemplates = async () => {
    setFetchingWhatsappTemplates(true);

    let requestBody = {
      filter: { status: "Approved" },
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody);

    setFetchingWhatsappTemplates(false);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
  };

  useEffect(() => {
    if (show) {
      fetchTemplates();
    }
  }, [show]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={editMode ? "Update Whatsapp Campaign" : "Create Whatsapp Campaign"}
      modalBodyClassName="p-1"
    >
      <div className="px-2 py-1">
        {fetchingWhatsappTemplates || loading ? (
          <Loader />
        ) : (
          <SendWhatsappCampaignForm
            editableSource={editableSource}
            showProgress={showProgress}
            whatsappTemplates={whatsappTemplates}
            allowOnSignUp={allowOnSignUp}
            onSubmit={onSubmit}
            events={events}
            automations={automations}
            inboundSource={inboundSource}
            initialValues={initialValues}
            submitButtonText={editMode ? "Update" : "Create"}
          />
        )}
      </div>
    </AppModal>
  );
};

export default CreateWhatsappCampaignModal;

import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";

// Yup validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Template name is required"),
  subject: Yup.string().required("Subject is required"),
  body: Yup.string().required("Template body is required"),
});

const defaultValues = {
  name: "",
  subject: "",
  body: "",
};
const AddEditEmailTemplateForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
}) => {
  const { translate, isRTL } = useLocalization();
  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className="">
            <Row className="m-0">
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("template_name")}
                  </FormLabel>

                  <Field name="name" className="form-control form-control-sm" />
                  <ErrorMessage
                    name={"name"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("subject")}
                  </FormLabel>

                  <Field
                    name={"subject"}
                    className="form-control form-control-sm"
                  />
                  <ErrorMessage
                    name={"subject"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("template_body")}
                  </FormLabel>

                  <ReactQuill
                    className={isRTL ? "rtl-editor" : "ltr-editor"}
                    theme="snow"
                    value={values?.body}
                    onChange={(body) => setFieldValue("body", body)}
                    placeholder="Write something awesome..."
                  />

                  <ErrorMessage
                    name={"subject"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end border-top">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditEmailTemplateForm;

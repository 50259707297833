import { MessageOutlined } from "@ant-design/icons";
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getWhatsappTemplateStyles } from "../../helpers/global";

const OverviewWhatsappTemplate = ({
  message,
  placement = "right",
  Element = (props) => <MessageOutlined {...props} className="text-dark" />,
}) => {
  // Dynamic styling when the tooltip is shown

  return (
    <OverlayTrigger
      placement={placement}
      onEntering={getWhatsappTemplateStyles} // Apply styles when entering
      overlay={
        <Tooltip style={{ zIndex: 999999999 }} aria-label="tooltip">
          {message || "No message available"}
        </Tooltip>
      }
    >
      <Button className="p-0" variant="transparent" size="sm">
        <Element />
      </Button>
    </OverlayTrigger>
  );
};

export default OverviewWhatsappTemplate;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Send, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR, makeRESTApiRequests } from "../../helpers/api";
import {
  ADMIN_ROLE,
  DEAL_STATUSES,
  DEAL_VIEWS,
  DEFAULT_PAGE_SIZE,
  ENDPOINTS,
} from "../../helpers/constants";
import { getDealsTableColumns } from "../../helpers/dataSheetConstants";
import {
  downloadFileFromString,
  updateItemsInArray,
} from "../../helpers/global";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { contractService } from "../../services/contractService";
import AlertModal from "../common/AlertModal";
import DataTable from "../common/data-table/DataTable";
import { findIfObjectIsInArrayOrNot } from "../contacts/BrowseContacts";
import CreateDealModal from "./CreateDealModal";
import ProsperoContractEditAndSendSidebar from "./ProsperoContractEditSidebar";
import DealsBoardView from "./board-view/DealsBoardView";
import DealsTabAndFilter from "./DealsTabAndFilter";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import { isNumber } from "lodash";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { isAdminOrManager } from "../../helpers/session";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import useSelection from "../../hooks/useSelection";
import ShowAppointmentModal from "./board-view/ShowAppointmentModal";

const checkIfDealHasRemainingPayment = (deal) => {
  const { payments = [], proposalQuote } = deal;
  const totalPayment = payments?.reduce((a, c) => a + c.amount, 0);

  return Math.round(totalPayment) !== Math.round(proposalQuote);
};

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseDeals = () => {
  const abortControllerRef = useRef(null);
  const { message } = useWebSocket();
  const users = useAppChoices("users");

  const products = useAppChoices("products");
  const units = useAppChoices("units");

  const { translate } = useLocalization();
  const { user } = useAuth();
  const { searchBoxOptions, setIsSearchBoxDisabled, isSearchBoxDisabled } =
    useSearchAndFilterBox();

  const { openDealPopup, updatedDeal, updatedContact } =
    useContactAndDealPopup();

  const [activeView, setActiveView] = useState(DEAL_VIEWS[0].name);
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState({
    key: "createdAt",
    order: "desc",
  });
  const [fetchingFirstPageDeals, setFetchingFirstPageDeals] = useState(false);
  const [fetchingMoreDeals, setFetchingMoreDeals] = useState(false);
  const [deleteContractModalMeta, setDeleteContractModalMeta] = useState(null);
  const [boardViewFilters, setBoardViewFilters] = useState({
    status: ["Signed"],
  });

  // board view deal dragging state
  const [updatingProductionStage, setUpdatingProductionStage] = useState(false);

  //original products array
  const [tableDeals, setTableDeals] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  // new deal
  const [newDealModalMeta, setNewDealModalMeta] = useState(null);
  const [prosperoContractEditMeta, setProsperoContractEditMeta] =
    useState(null);

  // board deals
  const boardDealabortControllerRef = useRef(null);

  const [boardDeals, setBoardDeals] = useState([]);
  const [fetchingBoardDeals, setFetchingBoardDeals] = useState(false);
  const [showFoatingContract, setShowFloatingContract] = useState(false);
  const [showTableFloatingContract, setShowTableFloatingContract] =
    useState(false);
  const [showDealsWithRemainingPayment, setShowDealsWithRemainingPayment] =
    useState(false);
  const [tableScrollTop, setTableScrollTop] = useState();
  const [lastUpdatedContract, setLastUpdatedContract] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    selectAllMode,
    setSelectAllMode,
    selectedItems,
    setSelectedItems,
    unselectedItems,
    setUnselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  } = useSelection(tableDeals);

  const [showDealAppointmentMeta, setShowDealAppointmentMeta] = useState(null);

  const handleShowMoreClick = (deal) => {
    setShowDealAppointmentMeta({
      deal,
      appointments: deal?.contactAppointments,
    });
  };

  const tableColumns = useMemo(
    () =>
      getDealsTableColumns({
        user,
        columnToHide: [],
        users,
        products,
        onToggleSelectAll,
        onToggleSelectItem,
        isItemSelected,
        selectAllMode,
        translate,
        handleShowMoreClick,
      }),
    [
      user,
      users,
      products,
      onToggleSelectAll,
      isItemSelected,
      onToggleSelectItem,
      selectAllMode,
      handleShowMoreClick,
    ]
  );

  const scrollToContactRow = (scrollTop) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const fetchDeals = async () => {
    if (
      !searchBoxOptions ||
      !toLoadPageInfo ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageInfo;

    const loadingMoreProducts = pageToFetch > 1;

    if (loadingMoreProducts) {
      setFetchingMoreDeals(true);
    } else {
      if (!isNotInitialFetch) {
        setFetchingFirstPageDeals(true);
      }
    }

    const { query } = searchBoxOptions;
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      query,
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: tableFilterValues,
    };

    const { response, error } = await contractService.getContracts(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreDeals(false);
    setFetchingFirstPageDeals(false);

    if (!isNotInitialFetch) {
      clearSelection();
    }

    if (error) {
      return toast.error(error);
    }

    if (isNumber(toLoadPageInfo?.scrollTop)) {
      scrollToContactRow(toLoadPageInfo?.scrollTop);
    }

    let { pageNumber, totalPages, results = [], resultCount } = response;

    results = showDealsWithRemainingPayment
      ? results.filter(checkIfDealHasRemainingPayment)
      : results;

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    setTableDeals((prevProducts) =>
      loadingMoreProducts ? [...prevProducts, ...results] : results
    );
  };

  const fetchBoardDeals = async () => {
    if (searchBoxOptions?.query && searchBoxOptions?.query.length < 2) {
      return;
    }

    // If an old API call is in progress, abort it
    try {
      if (boardDealabortControllerRef.current) {
        boardDealabortControllerRef.current.abort();
      }

      setFetchingBoardDeals(true);

      const controller = new AbortController();
      const { signal } = controller;

      boardDealabortControllerRef.current = controller;
      let { query } = searchBoxOptions;
      const requestBody = {
        query,
        filter: [
          { key: "unit", value: boardViewFilters?.product },
          { key: "assignedTo", value: boardViewFilters?.assignedTo },
          { key: "status", value: boardViewFilters?.status },
          { key: "isFloating", value: showFoatingContract },
        ],
        attachContactAppointments: true,
      };

      const { response, error } = await contractService.getContracts(
        requestBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      setFetchingBoardDeals(false);

      if (error) {
        return toast.error(error);
      }
      const { results = [] } = response;
      setBoardDeals(results);
    } catch (error) {
      console.log(error);
    }
  };

  const allContractFilters = useMemo(() => {
    const { query } = searchBoxOptions;
    return {
      query,
      sort: tableSortOption,
      filter:
        activeView === "board"
          ? [
              { key: "unit", value: boardViewFilters?.product },
              { key: "assignedTo", value: boardViewFilters?.assignedTo },
              { key: "status", value: boardViewFilters?.status },
              { key: "isFloating", value: showFoatingContract },
            ]
          : tableFilterValues,
    };
  }, [
    activeView,
    tableFilterValues,
    searchBoxOptions,
    tableSortOption,
    boardViewFilters,
  ]);

  const fetchDealsAfterUpdate = (scrollTop) => {
    // fetches contacts and scrolls back to previous position
    setToLoadPageInfo({
      ...loadedPageInfo,
      pageSize: loadedPageInfo?.pageNumber * DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      scrollTop,
      isNotInitialFetch: true,
    });
    fetchBoardDeals();
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageDeals || fetchingMoreDeals) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      scrollTop: undefined,
      isNotInitialFetch: true,
    });
  };

  const onDealClick = useCallback((contract) => {
    openDealPopup(contract?._id);
  }, []);

  const onDeleteSelectedContractClick = () => {
    setDeleteContractModalMeta({
      contractToBeDeleted: selectedItems,
      showProgress: false,
    });
  };

  const deleteSelectedDeals = async () => {
    const { contractToBeDeleted } = deleteContractModalMeta;

    setDeleteContractModalMeta((deleteModalMeta) => ({
      ...deleteModalMeta,
      showProgress: true,
    }));

    try {
      const { response, error } = await contractService.deleteSelectedContract({
        ids: contractToBeDeleted?.map((deal) => deal._id),
      });
      if (response) {
        let updatedDeals = tableDeals.filter(
          (deal) => !contractToBeDeleted.find((c) => c._id === deal?._id)
        );
        setTableDeals(updatedDeals);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteContractModalMeta(null);
    }
  };

  const onCreateNewDealClick = () => {
    setNewDealModalMeta({});
  };

  const onNewDealSubmit = async (contractDetails) => {
    setNewDealModalMeta({ showProgress: true });
    const { sendImmediately } = contractDetails;

    const { response, error } = await contractService.createContract(
      contractDetails
    );

    if (error) {
      setNewDealModalMeta({ showProgress: false });
      return toast.error(error);
    }

    setNewDealModalMeta(null);
    if (response) {
      let updatedDeals = [response, ...tableDeals];
      setTableDeals(updatedDeals);
      setLoadedPageInfo({
        ...loadedPageInfo,
        resultCount: (loadedPageInfo?.resultCount || 0) + 1,
      });
    }

    if (sendImmediately) {
      toast.success("Deal Sent Successfully");
      return;
    }

    setProsperoContractEditMeta({ contract: response });
  };

  const onContractEditAndSendClick = async () => {
    const { contract } = prosperoContractEditMeta;
    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: true,
    });

    const { response, error } = await contractService.sendContractViaEmail({
      contractId: contract._id,
    });

    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: false,
    });

    if (error) {
      return toast.error(error);
    }

    toast.success("Deal Sent Successfully");

    setProsperoContractEditMeta(null);
  };

  const onProductionStageDragEnd = async (reqBody) => {
    try {
      setUpdatingProductionStage(true);
      const contractUpdatePayload = reqBody;

      let originalTableDeals = [...tableDeals];
      let originalBoardDeals = [...boardDeals];

      let updatedDeal = boardDeals?.find(
        (d) => contractUpdatePayload?._id === d?._id
      );

      updatedDeal.units = contractUpdatePayload?.units;

      const updatedTableDeals = [
        ...updateItemsInArray(originalTableDeals, updatedDeal),
      ];
      const updatedBoardDeals = [
        ...updateItemsInArray(boardDeals, updatedDeal),
      ];

      setTableDeals(updatedTableDeals);
      setBoardDeals(updatedBoardDeals);

      const { response, error } = await contractService.updateContract(
        contractUpdatePayload
      );

      if (error) {
        toast.error(error);
        setBoardDeals(originalBoardDeals);
        setTableDeals(originalTableDeals);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProductionStage(false);
    }
  };

  const toggleRemainingPaymentDealFilter = (checked) => {
    setTableFilterValues(
      checked ? [{ key: "status", value: "Deal Finished" }] : []
    );

    setShowDealsWithRemainingPayment(checked);
    clearSelection();
  };

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo });
    },
    [
      tableFilterValues,
      tableSortOption,
      searchBoxOptions,
      showDealsWithRemainingPayment,
    ],
    300
  );

  const downloadContractedBuyersCSV = async () => {
    const requestBody = {
      ...allContractFilters,
      timezoneOffset: new Date().getTimezoneOffset(),
    };

    toast.info("File will be downloaded shortly...");
    setIsDownloading(true);
    const { response, error } = await makeRESTApiRequests({
      requestBody,
      endpoint: ENDPOINTS.CONTRACTS_DOWNLOAD_CSV,
      method: "POST",
    });
    setIsDownloading(false);

    if (error) {
      toast.error(error);
      console.log("error:", error);
      return;
    }

    const { csv } = response;
    downloadFileFromString({ data: csv, fileName: "Contracts.csv" });
  };

  useEffect(() => {
    setTableFilterValues((prevFilterValues) => {
      return updateItemsInArray(
        prevFilterValues,
        { key: "isFloating", value: showTableFloatingContract },
        "key"
      );
    });
  }, [showTableFloatingContract]);

  useEffect(() => {
    fetchDeals();
  }, [toLoadPageInfo]);

  useEffect(() => {
    fetchBoardDeals();
  }, [boardViewFilters, showFoatingContract, searchBoxOptions]);

  useEffect(() => {
    // update deals if popup deal changes
    if (tableDeals?.length <= 0) return;
    if (Boolean(updatedDeal)) {
      let { action, deal } = updatedDeal;

      if (action === "delete") {
        fetchDealsAfterUpdate(tableScrollTop);
      }
      if (action === "update") {
        fetchDealsAfterUpdate(tableScrollTop);
      }
    }
  }, [updatedDeal]);

  useEffect(() => {
    // update deals if contact changes to show in table
    if (Boolean(updatedContact)) {
      let { action, contact } = updatedContact;
      if (action === "delete") {
        let updatedTableDeals = tableDeals?.filter(
          (d) => d?.contact?._id !== contact?._id
        );
        let updatedBoardDeals = boardDeals?.filter(
          (d) => d?.contact?._id !== contact?._id
        );
        setTableDeals(updatedTableDeals);
        setBoardDeals(updatedBoardDeals);
      }
      if (action === "update") {
        let updatedTableDeals = tableDeals.map((deal) => {
          if (deal?.contact?._id === contact?._id) {
            return { ...deal, contact };
          }
          return deal;
        });
        let updatedBoardDeals = boardDeals.map((deal) => {
          if (deal?.contact?._id === contact?._id) {
            return { ...deal, contact };
          }
          return deal;
        });
        setTableDeals(updatedTableDeals);
        setBoardDeals(updatedBoardDeals);
      }
    }
  }, [updatedContact]);

  useDebouncedEffect(
    () => {
      if (!message) return;

      const { type, payload } = message;

      switch (type) {
        case BROADCAST_TYPES.CONTRACT_UPDATE:
          fetchDealsAfterUpdate(tableScrollTop);
          setLastUpdatedContract(payload._id);
          break;

        case BROADCAST_TYPES.CONTRACT_CREATE:
          fetchDealsAfterUpdate(tableScrollTop);
          break;
      }
    },
    [message],
    500
  );

  useEffect(() => {
    if (!message) return;

    const { type, payload } = message;

    const updateDeals = (dealsList, updateFn) => dealsList.map(updateFn);

    const filterDeals = (dealsList, filterFn) => dealsList.filter(filterFn);

    switch (type) {
      case BROADCAST_TYPES.CONTACT_UPDATE:
        if (payload?._id) {
          const updateContact = (deal) =>
            deal?.contact?._id === payload._id
              ? { ...deal, contact: payload }
              : deal;

          setTableDeals(updateDeals(tableDeals, updateContact));
          setBoardDeals(updateDeals(boardDeals, updateContact));
        }
        break;

      case BROADCAST_TYPES.CONTACT_DELETE:
        if (payload?._id) {
          const filterContact = (deal) => deal?.contact?._id !== payload._id;

          setTableDeals(filterDeals(tableDeals, filterContact));
          setBoardDeals(filterDeals(boardDeals, filterContact));
        }
        break;

      case BROADCAST_TYPES.CONTRACT_DELETE:
        const filterContract = (deal) => deal._id !== payload._id;

        setTableDeals(filterDeals(tableDeals, filterContract));
        setBoardDeals(filterDeals(boardDeals, filterContract));
        break;
    }
  }, [message]);

  useEffect(() => {
    setActiveView(DEAL_VIEWS[0].name);
  }, []);

  useEffect(() => {
    if (!isAdminOrManager(user.role)) {
      setBoardViewFilters((prevFilter) => ({
        ...prevFilter,
        assignedTo: [user._id],
      }));
    }
  }, [user]);

  useEffect(() => {
    if (!lastUpdatedContract) return;
    setTimeout(() => {
      setLastUpdatedContract(null);
    }, 500);
  }, [lastUpdatedContract]);

  const totalPageDate = useMemo(
    () => loadedPageInfo?.resultCount,
    [loadedPageInfo]
  );

  return (
    <>
      <Container fluid className={`d-flex flex-column px-2 pb-2`}>
        <div className={`flex-grow-1`}>
          <DealsTabAndFilter
            activeView={activeView}
            setActiveView={setActiveView}
            products={units}
            users={users}
            deals={tableDeals}
            boardViewFilters={boardViewFilters}
            setBoardViewFilters={setBoardViewFilters}
            showFoatingContract={showFoatingContract}
            setShowFloatingContract={setShowFloatingContract}
            showTableFloatingContract={showTableFloatingContract}
            setShowTableFloatingContract={setShowTableFloatingContract}
            showDealsWithRemainingPayment={showDealsWithRemainingPayment}
            toggleRemainingPaymentDealFilter={toggleRemainingPaymentDealFilter}
            isSearchBoxDisabled={isSearchBoxDisabled}
            isDownloading={isDownloading}
            downloadContractedBuyersCSV={downloadContractedBuyersCSV}
          />
          {activeView === "table" && (
            <DataTable
              maxTableHeight={"calc(100vh - 135px)"}
              rowKey={"_id"}
              columns={tableColumns}
              data={tableDeals}
              rowToHighlight={lastUpdatedContract}
              sortOptions={tableSortOption}
              onTableScrollTopChange={setTableScrollTop}
              onSortChange={setTableSortOption}
              filterValues={tableFilterValues}
              onFilterValuesChange={setTableFilterValues}
              onRowClick={onDealClick}
              onBottomReached={loadMoreData}
              loadingMoreData={fetchingMoreDeals}
              bottomOffset={50}
              loadingFirstPageData={fetchingFirstPageDeals}
              allowFilter={true}
              allowSort={true}
            />
          )}
          {activeView === "board" && (
            <DealsBoardView
              fetchingBoardDeals={fetchingBoardDeals}
              maxHeight={"calc(100vh - 135px)"}
              boardDeals={boardDeals}
              boardViewFilters={boardViewFilters}
              products={units}
              onDealClick={onDealClick}
              updatingProductionStage={updatingProductionStage}
              onProductionStageDragEnd={onProductionStageDragEnd}
            />
          )}
        </div>

        <div
          className="d-flex align-items-center py-1 w-100 bg-white border-top"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        >
          <div className="flex-grow-1 d-flex align-items-center">
            {/* Show selected contact count */}
            <Button
              size="sm"
              onClick={onCreateNewDealClick}
              variant="success"
              className="align-items-center d-flex text-white py-0 mx-1"
            >
              <Send size={10} className="mx-1" />
              {translate("create_new_deal")}
            </Button>

            {(selectedItems.length > 0 || selectAllMode) && (
              <>
                <Button
                  className="smallFont py-0 mx-1"
                  variant="dark"
                  size="sm"
                >
                  <span className="text-white smallFont mx-1">
                    {selectAllMode
                      ? totalPageDate - unselectedItems?.length
                      : selectedItems?.length}{" "}
                    / {totalPageDate ?? 0} Selected
                  </span>
                </Button>
                {/* Delete selected contact button */}
                {isAdminOrManager(user?.role) && !selectAllMode && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="px-1 py-0 mx-1"
                    onClick={onDeleteSelectedContractClick}
                  >
                    <Trash className="mb-1" />
                  </Button>
                )}
                {/* Edit selected contact button */}
              </>
            )}
          </div>
        </div>
      </Container>
      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(deleteContractModalMeta)}
          onHide={() => setDeleteContractModalMeta(false)}
          onDismissClick={() => setDeleteContractModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_contracts")}
          showProgress={deleteContractModalMeta?.showProgress}
          onContinueClick={deleteSelectedDeals}
        />
      )}

      <CreateDealModal
        show={Boolean(newDealModalMeta)}
        showProgress={newDealModalMeta?.showProgress}
        onHide={() => setNewDealModalMeta(null)}
        onSubmit={onNewDealSubmit}
      />
      <ProsperoContractEditAndSendSidebar
        contract={prosperoContractEditMeta?.contract}
        show={Boolean(prosperoContractEditMeta)}
        onHide={() => setProsperoContractEditMeta(false)}
        onContractEditAndSendClick={onContractEditAndSendClick}
        showProgress={prosperoContractEditMeta?.showProgress}
      />
      <ShowAppointmentModal
        show={Boolean(showDealAppointmentMeta)}
        onHide={() => setShowDealAppointmentMeta(null)}
        deal={showDealAppointmentMeta?.deal}
        showDealAppointmentMeta={showDealAppointmentMeta}
        onCloseAppointmentDetail={() =>
          setShowDealAppointmentMeta({
            ...showDealAppointmentMeta,
            activeAppointment: null,
          })
        }
        onAppointmentClick={(appointment) =>
          setShowDealAppointmentMeta({
            ...showDealAppointmentMeta,
            activeAppointment: appointment,
          })
        }
      />
    </>
  );
};

export default BrowseDeals;

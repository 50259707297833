import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { appConfigurationService } from "../../services/appConfigurationService";
import { filterServices } from "../../services/filterServices";
import { statusServices } from "../../services/statusService";
import { tagsServices } from "../../services/tagsServices";
import Loader from "../common/Loader";
import AutoMerge from "./auto-merge";
import CompoundFilter from "./compound-filter";
import Statuses from "./contact-status/Status";
import AppointmentDefaultType from "./event-type/AppointmentDefaultType";
import Tags from "./tags/Tags";
import WhatsappTemplates from "./whatsapp-message-template/WhatsappTemplates";
import EmailTemplates from "./email-templates/EmailTemplates";

const AppConfiguration = () => {
  const { templateId } = useQueryParams();
  const { screenHeight } = useScreenWidth();
  const [shouldScroll, setShouldScroll] = useState(true);
  const [appointmentDefaultTypes, setAppointmentDefaultTypes] = useState([]);
  const [fetchingAppointmentTypes, setFetchingAppointmentTypes] =
    useState(true);
  const [tags, setTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [fetchingStatuses, setFetchingStatuses] = useState(true);

  const [filterData, setFilterData] = useState([]);
  const [fetchingFilter, setFetchingFilter] = useState(true);

  const containerRef = useRef(null);

  const fetchAppointmentDefaultTypes = async () => {
    try {
      setFetchingAppointmentTypes(true);
      const { response, error } =
        await appConfigurationService.getAppointmentDefalutType();
      if (error) {
        return toast.error(error);
      }
      setAppointmentDefaultTypes(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingAppointmentTypes(false);
    }
  };

  const fetchTags = async () => {
    try {
      setFetchingTags(true);
      const { response, error } = await tagsServices.getTags();
      if (error) {
        return toast.error(error);
      }
      setTags(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingTags(false);
    }
  };

  const fetchStatuses = async () => {
    try {
      setFetchingStatuses(true);
      const { response, error } = await statusServices.getStatuses();
      if (error) {
        return toast.error(error);
      }
      setStatuses(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingStatuses(false);
    }
  };

  const fetchPresetFilters = async () => {
    try {
      setFetchingFilter(true);
      const { response, error } = await filterServices.getFilters();
      if (response) {
        setFilterData(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingFilter(false);
    }
  };

  useEffect(() => {
    fetchPresetFilters();
    fetchAppointmentDefaultTypes();
    fetchTags();
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (
      templateId &&
      containerRef.current &&
      !fetchingAppointmentTypes &&
      !fetchingFilter &&
      !fetchingTags &&
      !fetchingStatuses &&
      shouldScroll
    ) {
      const containerElement = containerRef.current;
      setTimeout(() => {
        containerElement.scrollTo({
          top: containerElement.scrollHeight,
          behavior: "smooth",
        });
        setShouldScroll(false);
      }, 300);
    }
  }, [
    templateId,
    fetchingAppointmentTypes,
    fetchingFilter,
    fetchingTags,
    fetchingStatuses,
  ]);

  return (
    <div
      style={{
        height: screenHeight - 60,
      }}
      className="overflow-auto py-3"
      ref={containerRef}
    >
      {fetchingAppointmentTypes ||
      fetchingFilter ||
      fetchingTags ||
      fetchingStatuses ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column gap-2">
          <Row className="m-0">
            <Col sm={12} md={6} lg={6}>
              <CompoundFilter
                filterData={filterData}
                setFilterData={setFilterData}
                tags={tags}
              />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Tags className={"h-100"} tags={tags} setTags={setTags} />
            </Col>{" "}
            <Col sm={6}>
              <AppointmentDefaultType
                className="mt-4"
                appointmentDefaultTypes={appointmentDefaultTypes}
                setAppointmentDefaultTypes={setAppointmentDefaultTypes}
              />
            </Col>
            <Col sm={6}>
              <EmailTemplates className="mt-4" />
            </Col>{" "}
            <Col sm={12} className="mt-4">
              <WhatsappTemplates />
            </Col>
            <Col sm={12} md={6} lg={6} className="mt-4">
              <Statuses
                className={"h-100"}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Col>
            <Col sm={12} md={6} lg={6} className="mt-4">
              <AutoMerge />
            </Col>{" "}
          </Row>
        </div>
      )}
    </div>
  );
};

export default AppConfiguration;

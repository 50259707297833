import React from "react";
import { components } from "react-select";
import { templateUrl } from "../../helpers/global";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";
import OverviewWhatsappTemplate from "./OverviewWhatsappTemplate";

const WhatsappTemplateDropdownOption = ({
  isOption = false,
  whatsappTemplates,
  data,
  options,
  ...props
}) => {
  const template = whatsappTemplates.find((t) => t._id === data?.value);
  const { user } = useAuth();
  return (
    <components.SingleValue {...props}>
      {data ? (
        <div
          className={`d-flex position-relative hover border-bottom align-items-center justify-content-between ${
            isOption ? "hover-light" : ""
          } px-2 py-1`}
          style={{ zIndex: 1000 }}
        >
          {isAdminOrManager(user.role) ? (
            <a
              className="mx-1 truncate"
              onClick={(e) => {
                e.stopPropagation();
              }}
              target="_blank"
              href={templateUrl(template)}
            >
              {template?.templateName}
            </a>
          ) : (
            <h6 className="mx-1 mb-0 smallFont truncate">
              {template?.templateName}
            </h6>
          )}

          <OverviewWhatsappTemplate message={template?.messageBody} />
        </div>
      ) : (
        ""
      )}
    </components.SingleValue>
  );
};
export default WhatsappTemplateDropdownOption;

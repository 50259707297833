import { isNumber } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import {
  DEFAULT_PAGE_SIZE,
  WHATSAPP_SOURCE_CONTACT_TABLE,
  WHATSAPP_SOURCE_QUICK_SEND,
} from "../../helpers/constants";
import { getWhatsappCampaignsTableColumns } from "../../helpers/dataSheetConstants";
import { updateItemsInArray } from "../../helpers/global";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { whatsappCampaignService } from "../../services/whatsappCampaignService";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";
import AlertModal from "../common/AlertModal";
import CreateWhatsappCampaignModal from "../common/CreateWhatsappCampaignModal";
import DataTable from "../common/data-table/DataTable";
import FloatingButton from "../common/FloatingButton";
import LogDetailModal from "./LogDetailModal";
import UpdateLogModal from "./UpdateLogModal";
import { automationService } from "../../services/automationService";
import { eventService } from "../../services/eventService";
import { inboundSourceDefaultsStatusService } from "../../services/inboundSourceDefaultsStatusService";
import useActiveTrailAndFacebookCampaign from "../../hooks/useActiveTrailAndFacebookCampaign";

const defaultSortOption = {
  key: "createdAt",
  order: "desc",
};

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseLogs = ({}) => {
  const abortControllerRef = useRef(null);
  const { screenHeight } = useScreenWidth();
  const { translate } = useLocalization();
  const { facebookCampaigns } = useActiveTrailAndFacebookCampaign();
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState(defaultSortOption);
  const [fetchingFirstPageLogs, setFetchingFirstPageLogs] = useState(false);
  const [fetchingMoreLogs, setFetchingMoreLogs] = useState(false);

  const [logs, setLogs] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [logDetailMeta, setLogDetailMeta] = useState(null);
  const [confirmationModalMeta, setConfirmationModalMeta] = useState(null);
  const [updateLogModalMeta, setUpdateLogModalMeta] = useState(null);

  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [newWhatsappMessageModalMeta, setNewWhatsappMessageModaMeta] =
    useState(null);

  const [automations, setAutomations] = useState([]);
  const [fetchingAutomations, setFetchingAutomations] = useState(false);
  const [events, setEvents] = useState([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [inboundSourceDefaults, setInboundSourceDefaults] = useState([]);
  const [fetchingInboundSourceDefaults, setFetchingInboundCourceDefaults] =
    useState(false);

  const scrollToContactRow = (scrollHeight) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onDeleteCampaignClick = (log) => {
    setConfirmationModalMeta({ log });
  };

  const onViewLogClick = (log) => {
    setLogDetailMeta({ log });
  };

  const onEditCampaignClick = (log) => {
    setUpdateLogModalMeta({ log });
  };

  const tableColumns = useMemo(
    () =>
      getWhatsappCampaignsTableColumns({
        whatsappTemplates,
        translate,
        onDeleteCampaignClick,
        onEditCampaignClick,
        onViewLogClick,
        events,
        automations,
        inbounds: inboundSourceDefaults,
        facebookCampaigns,
      }),
    [
      whatsappTemplates,
      events,
      automations,
      inboundSourceDefaults,
      facebookCampaigns,
    ]
  );

  const deleteCampaign = async () => {
    const { log } = confirmationModalMeta;
    setConfirmationModalMeta({ ...confirmationModalMeta, showProgress: true });
    const { response, error } =
      await whatsappCampaignService.deleteWhatsappCampaign(log._id);

    if (error) {
      toast.error(error);
      setConfirmationModalMeta({
        ...confirmationModalMeta,
        showProgress: false,
      });
      return;
    }
    setConfirmationModalMeta(null);
    setLogs(logs.filter((l) => l._id !== log._id));
    toast.success("Whatsapp campaign deleted succesfully");
  };

  const updateCampaign = async ({ sourceInfo, allSchedules }) => {
    const { log } = updateLogModalMeta;
    setUpdateLogModalMeta({ ...confirmationModalMeta, showProgress: true });
    let requestBody = {
      schedules: [allSchedules],
      ...sourceInfo,
    };
    const { response, error } =
      await whatsappCampaignService.updateWhatsappCampaign(
        log._id,
        requestBody
      );

    if (error) {
      toast.error(error);
      setUpdateLogModalMeta({
        ...confirmationModalMeta,
        showProgress: false,
      });
      return;
    }
    setUpdateLogModalMeta(null);
    setLogs(updateItemsInArray(logs, response));
    toast.success("Whatsapp campaign updated succesfully");
  };

  const fetchLogs = async () => {
    if (!toLoadPageInfo) {
      return;
    }
    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch } = toLoadPageInfo;
    const loadingMoreProducts = pageToFetch > 1;

    if (loadingMoreProducts) {
      setFetchingMoreLogs(true);
    } else {
      setFetchingFirstPageLogs(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: tableFilterValues,
    };

    const { response, error } =
      await whatsappCampaignService.getWhatsappCampaigns(requestBody, signal);
    if (error === ABORT_ERROR) return;

    setFetchingMoreLogs(false);
    setFetchingFirstPageLogs(false);

    if (error) {
      return toast.error(error);
    }

    if (isNumber(toLoadPageInfo?.scrollHeight)) {
      scrollToContactRow(toLoadPageInfo?.scrollHeight);
    }

    const { pageNumber, totalPages, results = [], resultCount } = response;

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    setLogs((prevProducts) =>
      loadingMoreProducts ? [...prevProducts, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageLogs || fetchingMoreLogs) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      isNotInitialFetch: true,
    });
  };

  const sendWhatsappCampaign = async ({ sourceInfo, allSchedules }) => {
    setNewWhatsappMessageModaMeta({
      ...newWhatsappMessageModalMeta,
      showProgress: true,
    });

    let requestBody = {
      allSchedules: [allSchedules],
      ...sourceInfo,
    };

    let { response, error } =
      await whatsappCampaignService.createWhatsappCampaign(requestBody);

    if (error) {
      toast.error(error);
      setNewWhatsappMessageModaMeta({
        ...newWhatsappMessageModalMeta,
        showProgress: false,
      });
      return;
    }
    setLogs([response, ...logs]);
    setNewWhatsappMessageModaMeta(null);
  };

  const fetchTemplates = async () => {
    const reqBody = { filter: { status: "Approved" } };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(reqBody);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
  };

  const fetchAutomations = async () => {
    try {
      const requestBody = {
        pageSize: 100,
        pageNumber: 1,
      };
      setFetchingAutomations(true);
      const { response, error } = await automationService.getAutomations(
        requestBody
      );

      if (error) {
        return toast.error(error);
      }

      const { results = [] } = response;
      setAutomations(results);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingAutomations(false);
    }
  };

  const fetchEvents = async () => {
    try {
      const requestBody = {
        pageSize: 100,
        pageNumber: 1,
      };
      setFetchingEvents(true);
      const { response, error } = await eventService.getEvents(requestBody);

      if (error) {
        return toast.error(error);
      }

      const { results = [] } = response;
      setEvents(results);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingEvents(false);
    }
  };

  const fetchDefaultStatus = async () => {
    try {
      setFetchingInboundCourceDefaults(true);
      const { response, error } =
        await inboundSourceDefaultsStatusService.getInboundSourceDefaultsStatus();
      if (error) {
        toast.error(error);
        return;
      }
      setInboundSourceDefaults(response);
      setFetchingInboundCourceDefaults(false);
    } catch (error) {
      console.log(error);
    }
  };

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo, scrollHeight: 0 });
    },
    [tableFilterValues, tableSortOption],
    500
  );

  useEffect(() => {
    fetchTemplates();
    fetchAutomations();
    fetchEvents();
    fetchDefaultStatus();
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [toLoadPageInfo]);

  return (
    <>
      <Container fluid className={`d-flex bg-white flex-column ${"px-2 pb-2"}`}>
        {" "}
        <div className="mt-2 d-flex align-items-center justify-content-between">
          <h6 className="mb-0 ">
            {translate("whatsapp_campaigns")} (
            {loadedPageInfo?.resultCount || 0})
          </h6>
        </div>
        <div className={`position-relative flex-grow-1 pt-2`}>
          <DataTable
            maxTableHeight={screenHeight - 150}
            rowKey={"_id"}
            columns={tableColumns}
            data={logs}
            sortOptions={tableSortOption}
            onSortChange={setTableSortOption}
            filterValues={tableFilterValues}
            onFilterValuesChange={setTableFilterValues}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreLogs}
            bottomOffset={300}
            loadingFirstPageData={fetchingFirstPageLogs}
          />
          <FloatingButton
            Icon={Plus}
            onClick={() =>
              setNewWhatsappMessageModaMeta({ showProgress: false })
            }
            text={"New Whatsapp message"}
            variant="success text-white"
            style={{
              bottom: -10,
            }}
          />
        </div>
      </Container>
      {Boolean(newWhatsappMessageModalMeta) && (
        <CreateWhatsappCampaignModal
          show={Boolean(newWhatsappMessageModalMeta)}
          onHide={() => setNewWhatsappMessageModaMeta(null)}
          showProgress={newWhatsappMessageModalMeta?.showProgress}
          allowOnSignUp
          editableSource
          loading={
            fetchingAutomations ||
            fetchingEvents ||
            fetchingInboundSourceDefaults
          }
          events={events}
          automations={automations}
          inboundSource={inboundSourceDefaults}
          onSubmit={sendWhatsappCampaign}
        />
      )}
      {Boolean(updateLogModalMeta) && (
        <CreateWhatsappCampaignModal
          show={Boolean(updateLogModalMeta)}
          onHide={() => setUpdateLogModalMeta(null)}
          initialValues={updateLogModalMeta?.log?.schedules?.[0]}
          whatsappTemplates={whatsappTemplates}
          allowOnSignUp={
            ![
              WHATSAPP_SOURCE_CONTACT_TABLE,
              WHATSAPP_SOURCE_QUICK_SEND,
            ].includes(updateLogModalMeta?.log?.sourceType)
          }
          loading={
            fetchingAutomations ||
            fetchingEvents ||
            fetchingInboundSourceDefaults
          }
          onSubmit={updateCampaign}
          showProgress={updateLogModalMeta?.showProgress}
          editMode
        />
      )}{" "}
      {Boolean(logDetailMeta) && (
        <LogDetailModal
          show={Boolean(logDetailMeta)}
          onHide={() => setLogDetailMeta(null)}
          log={logDetailMeta?.log}
          whatsappTemplates={whatsappTemplates}
        />
      )}
      <AlertModal
        show={confirmationModalMeta}
        onContinueClick={deleteCampaign}
        onDismissClick={() => setConfirmationModalMeta(null)}
        onHide={() => setConfirmationModalMeta(null)}
        showProgress={confirmationModalMeta?.showProgress}
        alertText={
          "Are you sure you want to delete this campaign? This action can't be undone"
        }
      />
    </>
  );
};

export default BrowseLogs;
